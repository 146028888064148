// https://javascript.plainenglish.io/creating-a-confirm-dialog-in-react-and-material-ui-3d7aaea1d799


import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MyTextField } from '../controls';


const DeleteConfirmDialog = (props) => {
  const { title, message, open, setOpen, onConfirm } = props;
  const [ textValue, setTextValue ] = useState("");

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{message}</Typography>
        <MyTextField 
          placeholder='DELETE'
          value={textValue}
          onValueChange={(value) => setTextValue(value)}
        />
        


      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="default"
          disabled={textValue !== 'DELETE'}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteConfirmDialog;