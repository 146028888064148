// React
import React from 'react';
// Material UI
import { Paper } from '@material-ui/core';
// Local
import SectionHeaderRow from './SectionHeaderRow';


export const DetailSection = ({title, children, style: styleProp, headerRight, ...otherProps}) => {

  const mergedStyle = Object.assign({display: 'flex', flexDirection: 'column'}, styleProp);

  return (
    <div style={mergedStyle} {...otherProps} >
      <SectionHeaderRow text={title} right={headerRight} />
      <Paper style={{ padding: '8px 16px', width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'start' }} >
        {children}   
      </Paper>
    </div>
  );
}


export const DetailSectionField = ({label, value, ...otherProps}) => {

  return (
    <div style={{ width: '100%', height: 40, display: 'flex', flexDirection: 'row', fontSize: '16px', alignItems: 'center' }} {...otherProps} >
      <div style={{ width: '30%' }} >{label + ':'}</div>
      <div style={{ width: '70%', fontWeight: 'bold' }} >{value}</div>
      {}
    </div>
  );

}


export default DetailSection;
