// React
import React, { useState, useEffect } from 'react';
// Material UI
import { Grid, TableContainer, Table, Paper, TableHead, TableBody, TableRow, TableCell, Button, Typography } from '@material-ui/core';
// Other Third-Party
import { navigate as rootNavigate } from "@reach/router"
// Local
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, CurrencyTextField, DateField, AddButton } from '../controls';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import Form from '../Form';
import { DeleteButton } from '../controls';
import { setDeep, toMMDDYYYY, today } from '../Util';
import { DetailSection } from '../layout/DetailSection';
import { createPayment, allocationTypeOptions } from '../model';
import { createLoanBL } from '../LoanBusinessLogic';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';



export default function Payment({paymentId, loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [payment, setPayment] = useState(undefined); 

  useEffect(() => {
    console.log('Payment', 'useEffect');
    if( loan === undefined )
      setPayment(undefined);
    else if( loan === 'not-found' )
      setPayment('not-found');
    else if( paymentId === 'new' )
      setPayment(createPayment());
    else if( ! loan.payments[paymentId] )
      setPayment('not-found');
    else
      setPayment(loan.payments[paymentId].clone() );
  }, [loan, paymentId]);


  const handleCancel = () => {
    rootNavigate(-1);
  }
  const handleSave = () => {
    const newLoan = loan.clone();
    if( paymentId === 'new' )
      newLoan.payments.push(payment);
    else
      newLoan.payments[paymentId] = payment;
    saveLoan(newLoan);
    rootNavigate(-1);
  }
  const handleDelete = () => {
    const newLoan = loan.clone();
    newLoan.payments.splice(paymentId, 1);
    saveLoan(newLoan).then( () => {
      rootNavigate(-1);
    });
  }

  const handleOnChange = (name, value) => {
    let newPayment = payment.clone();
    setDeep( newPayment, name, value );
    setPayment( newPayment );
  };


  const handleOnAddUnschAllocation = () => {
    let newPayment = payment.clone();
    const newAllocation = newPayment.newAllocation();
    newAllocation.asOfDate = (payment.receivedDate || undefined);
    newAllocation.type = 'unscheduled';
    setPayment( newPayment );
  }

  const handleOnDeleteAllocation = (index) => {
    let newPayment = payment.clone();
    newPayment.allocations.splice(index,1);
    setPayment( newPayment );
  }

  const handleOnChangeAllocation = (index, name, value) => {
    handleOnChange('allocations.' + index + '.' + name, value);
  }


  const handleApplyClick = (paymentDate) => {
    let newPayment = payment.clone();
    if( payment.allocations.length === 1 && payment.allocations[0].interest + payment.allocations[0].principal + payment.allocations[0].charges === 0 )
      newPayment.allocations = [];
    if( paymentDate === 'payoff' ) {
      const loanBL = createLoanBL(loan);
      const payoff = loanBL.getPayoff(loan, payment.receivedDate);
      const newAllocation = newPayment.newAllocation();
      newAllocation.type = 'unscheduled';
      newAllocation.asOfDate = payment.receivedDate;
      newAllocation.interest = payoff.interest;
      newAllocation.principal = payoff.principal;
      newAllocation.charges = payoff.charges;
      newPayment.suspense = -payoff.suspense;

      if( newPayment.cash === undefined || newPayment.cash === 0 )
        newPayment.cash = payoff.total;
    }
    else {
      const loanBL = createLoanBL(loan);
      const period = loanBL.scheduledPayments.find( p => p.paymentDate === paymentDate );
      const newAllocation = newPayment.newAllocation();
      newAllocation.asOfDate = period.paymentDate;
      newAllocation.type = 'scheduled';
      newAllocation.interest = period.remaining.interest;
      newAllocation.principal = period.remaining.principal;
      newAllocation.charges = period.remaining.charges;

      console.log('period', period);

      if( newPayment.cash === undefined || newPayment.cash === 0 )
        newPayment.cash = period.remaining.total;
    }
    setPayment( newPayment );
  }

  const handleApplyDueNow = () => {
    const loanBL = createLoanBL(loan);
    const pmts = loanBL.scheduledPayments.filter( p => ['Due Today','Late'].includes(p.status) );

    const newPayment = createPayment();
    newPayment.receivedDate = today();
    newPayment.cash = pmts.reduce( (s,p) => s + p.remaining.total, 0 );
    newPayment.suspense = 0;
    newPayment.allocations = pmts.map( p =>{
      const newAllocation = newPayment.newAllocation();
      newAllocation.asOfDate = p.paymentDate;
      newAllocation.type = 'scheduled';
      newAllocation.interest = p.remaining.interest;
      newAllocation.principal = p.remaining.principal;
      newAllocation.charges = p.remaining.charges;
      return newAllocation;
    });

    setPayment( newPayment );
  }

  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;


  if( loan === undefined || payment === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  if( payment === 'not-found' )
    return ( 
      <SaveCancelDialog title='Not Found' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );

  const payoff = (payment.receivedDate && payment.receivedDate.length > 0) ? loanBL.getPayoff(loan, payment.receivedDate) : undefined;
  const applyToPayments = loanBL.scheduledPayments.filter( p => p.remaining.total > 0 )
                                .filter( p => payment.allocations.find( a => a.type === 'scheduled' && a.asOfDate === p.paymentDate ) === undefined )
                                .sort( (a,b) => a.asOfDate - b.asOfDate )
                                .slice(0,3)
                                .map( (p,index) => ({ 
                                      paymentDate: p.paymentDate, 
                                      totalRemaining: p.due.total - p.paid.total }) );


  // Determine if we are going to suggest a payment
  let suggestedPayment = undefined;
  if( payment.receivedDate && payment.receivedDate.length > 0 && payment.cash > 0 ) {
    if( Math.abs( payoff.total - payment.cash ) / payment.cash < 0.10 )
      suggestedPayment = 'payoff'
    else if( applyToPayments.length > 0 && Math.abs((applyToPayments[0].totalRemaining - payment.cash) / payment.cash < 0.10 ) )
      suggestedPayment = applyToPayments[0].paymentDate;
  }

  const suspenseBalance = (loanBL.currentBalance.suspense - (paymentId === 'new' ? 0 : payment.suspense)).round2();
  const dueNow = loanBL.scheduledPayments.filter( p => ['Due Today','Late'].includes(p.status) ).reduce( (s,p) => s + p.remaining.total, 0 );
  
  return (
    <SaveCancelDialog title='Payment' onCancel={() => handleCancel()} onSave={() => handleSave()}
                      onDelete={ paymentId !== 'new' && (!isReadOnly) && ( () => handleDelete() )}
                      disableSave={(!payment.isValid()) || isReadOnly} >
      <Grid container spacing={3} style={{ margin: 0 }} >

        <Grid item md={6} xs={12} >
          <DetailSection title='Payment Received' style={{height: '100%'}} headerRight={
            <Button onClick={() => handleApplyDueNow()}
                    disabled={(isReadOnly)} ><ArrowDownwardIcon style={{marginRight: 4}} />${dueNow.format2()} Due</Button>
            } >
            <Form disabled={isReadOnly} >
              <DateField          
                label="Date"
                value={payment.receivedDate}
                onValueChange={(value) => handleOnChange("receivedDate", value)}
              />
              <CurrencyTextField
                label="Amount"
                value={payment.cash}
                onValueChange={(value) => handleOnChange("cash", value)}
              />
              <MyTextField
                label="Notes"
                value={payment.notes || ''}
                onValueChange={(value) => handleOnChange("notes", value)}
              />
            </Form>
          </DetailSection>
        </Grid>

        <Grid item md={6} xs={12} >
          <DetailSection title='Amounts Due' style={{height: '100%'}} >
            <TableContainer >
              <Table style={{minWidth: 300}} >
                <TableHead>
                  <TableRow >
                    <TableCell align="left" style={{padding: '8px'}} >Payment</TableCell>
                    <TableCell align="right" style={{padding: '8px'}}>Amount</TableCell>
                    <TableCell align="center" style={{padding: '8px'}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { applyToPayments.length === 0 ?
                    <TableRow >
                      <TableCell align="center" colSpan='3' style={{padding: '8px'}} >No suggestions</TableCell>
                    </TableRow>
                    :
                    applyToPayments.map( (p,index) => 
                      <TableRow key={p.paymentDate} >
                        <TableCell align="left" style={{padding: '8px'}} >{toMMDDYYYY(p.paymentDate)}</TableCell>
                        <TableCell align="right" style={{padding: '8px'}} >{p.totalRemaining.format2()}</TableCell>
                        <TableCell align="right" style={{padding: '8px'}} >
                          <Button onClick={() => handleApplyClick(p.paymentDate)} style={{ width: 50 }} 
                            variant={suggestedPayment === p.paymentDate ? 'outlined' : undefined }
                            color={suggestedPayment === p.paymentDate ? 'primary' : undefined }
                            disabled={isReadOnly}
                            >Apply</Button>
                        </TableCell>
                      </TableRow>
                    )
                }
                { payoff &&
                      <TableRow >
                        <TableCell align="left" style={{padding: '8px'}} >Payoff</TableCell>
                        <TableCell align="right" style={{padding: '8px'}} >{payoff.total.format2()}</TableCell>
                        <TableCell align="right" style={{padding: '8px'}} >
                          <Button onClick={() => handleApplyClick('payoff')} style={{ width: 50 }} 
                            variant={suggestedPayment === 'payoff' ? 'outlined' : undefined }
                            color={suggestedPayment === 'payoff' ? 'primary' : undefined }
                            disabled={isReadOnly} 
                            >Apply</Button>
                          </TableCell>
                      </TableRow>
                    }
                </TableBody>
              </Table>
            </TableContainer>
          </DetailSection>
        </Grid>

        <Grid item xs={12} >
          <SectionHeaderRow text='Apply To'
                            right={ <div><AddButton onAdd={() => handleOnAddUnschAllocation()} label='Unscheduled' disabled={(!currentUserIsOwner)} /></div> } />
          <TableContainer component={Paper} >
            <Table style={{minWidth: 650}} >
              <TableHead>
                <TableRow >
                  <TableCell align="left" >As Of Date</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="right">Interest</TableCell>
                  <TableCell align="right">Principal</TableCell>
                  <TableCell align="right">Charges</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { payment.allocations.length === 0 ?
                  <TableRow >
                    <TableCell align="center" colSpan='6' >No allocations</TableCell>
                  </TableRow>
                  : payment.allocations
                        .sort( (a,b) => a.asOfDate - b.asOfDate )
                        .map( (a,index) => {

                  return (
                    <TableRow key={index} >
                      <TableCell align="left" >
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                          <DateField          
                            value={a.asOfDate}
                            onValueChange={(value) => handleOnChangeAllocation(index, "asOfDate", value)}
                            disabled={a.type === 'scheduled' || isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell align='left' >
                        {allocationTypeOptions.find( t => t.value === a.type ).label}
                      </TableCell>
                      <TableCell >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} >
                          <CurrencyTextField
                            value={a.interest}
                            onValueChange={(value) => handleOnChangeAllocation(index, 'interest', value)}
                            disabled={isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} >
                          <CurrencyTextField
                            value={a.principal}
                            onValueChange={(value) => handleOnChangeAllocation(index, 'principal', value)}
                            disabled={isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} >
                          <CurrencyTextField
                            value={a.charges}
                            onValueChange={(value) => handleOnChangeAllocation(index, 'charges', value)}
                            disabled={isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <DeleteButton onDelete={() => handleOnDeleteAllocation(index)}
                            disabled={isReadOnly} />
                      </TableCell>
                    </TableRow> );
                })}

              </TableBody>
              <TableHead>
                <TableRow >
                  <TableCell align="left" ></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Suspense:</TableCell>
                  <TableCell align="right">
                    <CurrencyTextField
                            value={payment.suspense}
                            onValueChange={(value) => handleOnChange('suspense', value)}
                            disabled={isReadOnly}
                          />
                    {suspenseBalance !== 0 &&
                      <Typography variant='body2' style={{fontStyle: 'italic', marginTop: '-4px', marginBottom: '-8px'}} >${suspenseBalance} balance</Typography>
                    }
                  </TableCell>
                  <TableCell align="right" style={{fontWeight: 'normal'}}></TableCell>
                </TableRow>
                <TableRow >
                  <TableCell align="left" ></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Applied:</TableCell>
                  <TableCell align="right">{payment.totalApplied().format2()}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
                <TableRow >
                  <TableCell align="left" ></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Unapplied:</TableCell>
                  <TableCell align="right">{payment.totalUnapplied().format2()}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </SaveCancelDialog>
  );

}






