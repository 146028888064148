import { isDate, round2Decimals } from '../Util';

const advanceTemplate = {
  asOfDate: undefined,
  principal: 0,
  prepaidInterest: 0,
  charges: [],
  
  netCharges: function() { return this.charges.filter( c => c.due === 'net' ).sum('charge') },

  netPrepaidInterest: function() { return this.prepaidInterestDue === 'net' ? (this.prepaidInterest || 0) : 0 },

  cash: function() { return (this.principal || 0) - this.netCharges() - this.netPrepaidInterest() },

  paymentDue: function() {
    const p = {
      paymentDate: this.asOfDate,
      principal: 0,
      interest: this.prepaidInterestDue === 'immediately' ? this.prepaidInterest : 0,
      charges: this.charges.filter( c => c.due === 'immediately' ).sum('charge')
    };
    p.total = round2Decimals( p.principal + p.interest + p.charges );
    return p;
  },

  isValid: function() {
    //console.log(this);
    const dueValues = ['net','immediately','termination'];
    if( ! isDate(this.asOfDate) ) return false;
    if( this.principal <= 0 ) return false;
    if( this.prepaidInterest < 0 ) return false;
    if( this.prepaidInterest !== 0 && !dueValues.includes(this.prepaidInterestDue) ) return false;
    for( let i=0; i<this.charges.length; i++ ) {
      if( (!this.charges[i].description) || this.charges[i].description.length === 0 ) return false;
      if( this.charges[i].amount <= 0 ) return false;
      if( ! dueValues.includes(this.charges[i].due) ) return false;
    }
    return true;
  },

  toPOJO: function() { return JSON.parse(JSON.stringify(this)) },

  clone: function() { return createAdvance(this.advanceId, this.toPOJO()) }
};


export const createAdvance = (advanceId, data) => {
  return Object.assign( {}, advanceTemplate, data, { advanceId: advanceId } );
}