// React
import React, { useState, useEffect } from 'react';
// Material UI
// Other Third-Party
// Local
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, CurrencyTextField, DateField } from '../controls';
import { DetailSection, DetailSectionField } from '../layout/DetailSection';
import Form from '../Form';
import { setDeep } from '../Util';
import { createRefund } from '../model';


export default function Refund({refundId, loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [refund, setRefund] = useState(undefined); 

  useEffect(() => {
    if( loan === undefined )
      setRefund(undefined);
    else if( loan === 'not-found' )
      setRefund('not-found');
    else if( refundId === 'new' )
      setRefund(createRefund());
    else if( ! loan.refunds[refundId] )
      setRefund('not-found');
    else
      setRefund(loan.refunds[refundId]);
  }, [loan, refundId]);

  const handleCancel = () => {
    navigate('/loans/' + loanId);
  }
  const handleSave = () => {
    const newLoan = loan.clone();
    if( refundId === 'new' )
      newLoan.refunds.push(refund);
    else
      newLoan.refunds[refundId] = refund;
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }
  const handleDelete = () => {
    const newLoan = loan.clone();
    newLoan.refunds.splice(refundId, 1);
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }

  const handleOnChange = (name, value) => {
    let newRefund = refund.clone();
    setDeep( newRefund, name, value );
    setRefund( newRefund );
  };

  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;


  if( refund === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  if( refund === 'not-found' )
    return ( 
      <SaveCancelDialog title='Not Found' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );

  return (
    <SaveCancelDialog title='Refund' 
                      onCancel={() => handleCancel()} 
                      onSave={() => handleSave()} 
                      onDelete={ refundId !== 'new' && (!isReadOnly) && ( () => handleDelete() )}
                      disableSave={isReadOnly || (!refund.isValid())} >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}  >
        <DetailSection title='Refund' >
          <Form disabled={isReadOnly} >
            <DateField          
              label="As Of"
              value={refund.asOfDate || ''}
              onValueChange={(value) => handleOnChange("asOfDate", value)}
            />
            <CurrencyTextField
              label="Principal"
              value={refund.principal}
              onValueChange={(value) => handleOnChange("principal", value)}
            />
            <CurrencyTextField
              label="Interest"
              value={refund.interest}
              onValueChange={(value) => handleOnChange("interest", value)}
            />
            <CurrencyTextField
              label="Charges"
              value={refund.charges}
              onValueChange={(value) => handleOnChange("charges", value)}
            />
            <CurrencyTextField
              label="Suspense"
              value={-refund.suspense}
              onValueChange={(value) => handleOnChange("suspense", -value)}
            />
            <DetailSectionField label='Total' value={'$' + refund.total()} />
            <MyTextField
              label="Notes"
              value={refund.notes || ''}
              onValueChange={(value) => handleOnChange("notes", value)}
            />
          </Form>
        </DetailSection>
      </div>
    </SaveCancelDialog>
  );

}






