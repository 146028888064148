import { isDate } from '../Util';

const refundTemplate = {
  asOfDate: undefined,
  principal: 0,
  interest: 0,
  charges: 0,
  suspense: 0,

  total: function() { return this.principal + this.interest + this.charges - this.suspense },

  toPOJO: function() { return JSON.parse(JSON.stringify(this)) },
  
  clone: function() { return createRefund(this.refundId, this.toPOJO()) },

  isValid: function() {
      if( ! isDate(this.asOfDate) ) return false;
      if( isNaN(this.principal) ) return false;
      if( isNaN(this.interest) ) return false;
      if( isNaN(this.charges) ) return false;
      if( isNaN(this.suspense) ) return false;
      return true;
  }
};

export const createRefund = (id, data) => {
  return Object.assign( {}, refundTemplate, data, { refundId: id } );
}
