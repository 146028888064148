// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';


import {Typography} from '@material-ui/core';

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

function SignInScreen({firebase}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '50px'}} >
        {/* <img src='/logo.png' style={{ height: '50px', marginRight: '5px' }} alt='Logo' /> */}
        <Typography variant='h1' color='primary' >Entryway Servicing</Typography>
      </div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
}

export default SignInScreen