// React
import React, { useState, useEffect } from 'react';
// Material UI
import { Grid, Divider } from '@material-ui/core';
// Other Third-Party
// Local
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, CurrencyTextField, DateField } from '../controls';
import { DetailSection, DetailSectionField } from '../layout/DetailSection';
import Form from '../Form';
import { setDeep, today, isDate, toMMDDYYYY } from '../Util';
import { createWriteoff } from '../model';


export default function Writeoff({writeoffId, loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [writeoff, setWriteoff] = useState(undefined); 

  useEffect(() => {
    if( loan === undefined )
      setWriteoff(undefined);
    else if( loan === 'not-found' )
      setWriteoff('not-found');
    else if( writeoffId === 'new' )
      setWriteoff(createWriteoff());
    else if( ! loan.writeoffs[writeoffId] )
      setWriteoff('not-found');
    else
      setWriteoff(loan.writeoffs[writeoffId]);
  }, [loan, writeoffId]);

  const handleCancel = () => {
    navigate('/loans/' + loanId);
  }
  const handleSave = () => {
    const newLoan = loan.clone();
    if( writeoffId === 'new' )
      newLoan.writeoffs.push(writeoff);
    else
      newLoan.writeoffs[writeoffId] = writeoff;
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }
  const handleDelete = () => {
    const newLoan = loan.clone();
    newLoan.writeoffs.splice(writeoffId, 1);
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }

  const handleOnChange = (name, value) => {
    let newWriteoff = writeoff.clone();
    setDeep( newWriteoff, name, value );
    setWriteoff( newWriteoff );
  };


  if( writeoff === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  if( writeoff === 'not-found' )
    return ( 
      <SaveCancelDialog title='Not Found' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );

  const payoffAsOf = isDate(writeoff.asOfDate) ? writeoff.asOfDate : today();
  const payoff = loanBL.getPayoff(loan, payoffAsOf);
  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;

  return (
    <SaveCancelDialog title='Writeoff' 
                      onCancel={() => handleCancel()} 
                      onSave={() => handleSave()} 
                      onDelete={ writeoffId !== 'new' && (!isReadOnly) && ( () => handleDelete() )}
                      disableSave={isReadOnly || (!writeoff.isValid())} >

      <Grid container spacing={3} style={{ margin: 0 }} >

        <Grid item md={6} xs={12} >
          <DetailSection title='Writeoff' >
            <Form disabled={isReadOnly} >
              <DateField          
                label="As Of"
                value={writeoff.asOfDate || ''}
                onValueChange={(value) => handleOnChange("asOfDate", value)}
              />
              <CurrencyTextField
                label="Principal"
                value={writeoff.principal}
                onValueChange={(value) => handleOnChange("principal", value)}
              />
              <CurrencyTextField
                label="Interest"
                value={writeoff.interest}
                onValueChange={(value) => handleOnChange("interest", value)}
              />
              <CurrencyTextField
                label="Charges"
                value={writeoff.charges}
                onValueChange={(value) => handleOnChange("charges", value)}
              />
              <CurrencyTextField
                label="Suspense"
                value={-writeoff.suspense}
                onValueChange={(value) => handleOnChange("suspense", -value)}
              />
              <DetailSectionField label='Total' value={'$' + writeoff.total()} />
              <MyTextField
                label="Notes"
                value={writeoff.notes || ''}
                onValueChange={(value) => handleOnChange("notes", value)}
              />
            </Form>
          </DetailSection>
        </Grid>
        
        <Grid item md={6} xs={12} >
          <DetailSection title='Balances' >
            <DetailSectionField label='As of' value={toMMDDYYYY(payoffAsOf)} />
            <DetailSectionField label='Principal' value={'$' + payoff.principal.format2()} />
            <DetailSectionField label='Interest' value={'$' + payoff.interest.format2()} />
            <DetailSectionField label='Charges' value={'$' + payoff.charges.format2()} />
            <DetailSectionField label='Suspense' value={'$' + payoff.suspense.format2()} />
            <Divider style={{width: '90%', margin: '16px'}} />
            <DetailSectionField label='Total' value={'$' + payoff.total.format2()} />
          </DetailSection>
        </Grid>
      </Grid>
    </SaveCancelDialog>
  );

}






