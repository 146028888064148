// React
import React from 'react';
// Material UI
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
// Other Thrid-Party
// Local
import Frame from '../layout/Frame';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import { toMMDDYYYY } from '../Util';


export default function PaymentsDetail({loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {

  if( loan === 'not-found' )
    return ( 
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
        <div>Not found</div>
      </Frame>
    );
  if( loan === undefined )
    return (
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
        <div>Loading...</div>
      </Frame>
    );

  const groupedPayments = loan.allPayments()
                              .group('asOfDate')
                              .map( g => ({
                                asOfDate: g.asOfDate,
                                payments: g.items,
                                schPmt: loanBL.scheduledPayments.find( s => s.paymentDate === g.asOfDate )
                              }));
  
  const addlSchPmts = loanBL.scheduledPayments.filter( s => groupedPayments.find( g => g.asOfDate === s.paymentDate ) === undefined );
  const statusColors = { Paid: 'green', Late: 'red', Overpaid: 'orange', Future: 'blue' };
  const list = groupedPayments.concat( addlSchPmts.map( s => ({ asOfDate: s.paymentDate, payments: [], schPmt: s }) ) )
                              .map( i => Object.assign( i, { statusColor: i.schPmt && statusColors[i.schPmt.status] } ) )
                              .sortBy('asOfDate');

  return (
    <Frame title='Payment History' backLabel={'Back'} backTo={-1} >
      
      <SectionHeaderRow text='Payment History' />

      <TableContainer component={Paper} >
        <Table style={{minWidth: 650}} >
          <TableHead>
            <TableRow>
              <TableCell align="left">Payment Date</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left" >Received</TableCell>
              <TableCell align="right" >Principal</TableCell>
              <TableCell align="right" >Interest</TableCell>
              <TableCell align="right" >Charges</TableCell>
              <TableCell align="right" >Suspense</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => {
              const backgroundColor = 'white';
              if( item.schPmt ) {
                return (
                  <React.Fragment key={index}>
                    <TableRow style={{backgroundColor: backgroundColor}} >
                      <TableCell align="left" rowSpan={item.payments.length === 0 ? 2 : item.payments.length + 2} style={{borderTop: '2px solid black'}} >{toMMDDYYYY(item.asOfDate)}</TableCell>
                      <TableCell align="left" rowSpan={item.payments.length === 0 ? 2 : item.payments.length + 2} style={{borderTop: '2px solid black', color: item.statusColor }} >{item.schPmt.status}</TableCell>
                      <TableCell align="left" style={{borderTop: '2px solid black'}} >Due</TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} >{item.schPmt.due.principal.format2()}</TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} >{item.schPmt.due.interest.format2()}</TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} >{item.schPmt.due.charges.format2()}</TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} >{item.schPmt.due.suspense.format2()}</TableCell>
                    </TableRow>
                    { item.payments.length === 0 ?
                      <TableRow style={{backgroundColor: backgroundColor}} >
                        <TableCell align="left" colSpan={4} >No payments received</TableCell>
                      </TableRow>
                      : item.payments.sortBy('receivedDate').map( (p,index) => 
                        <TableRow key={index} style={{backgroundColor: backgroundColor, cursor: 'pointer'}} hover={true}
                                              onClick={() => navigate('../payments/' + p.paymentId)} >
                          <TableCell align="left">{toMMDDYYYY(p.receivedDate)}</TableCell>
                          <TableCell align="right">{p.principal.format2()}</TableCell>
                          <TableCell align="right">{p.interest.format2()}</TableCell>
                          <TableCell align="right">{p.charges.format2()}</TableCell>
                          <TableCell align="right">{p.suspense.format2()}</TableCell>
                        </TableRow> 
                      )
                    }
                    { item.payments.length > 0 &&
                      <TableRow style={{backgroundColor: backgroundColor}} >
                        <TableCell align="left" ><b>Unpaid</b></TableCell>
                        <TableCell align="right"><b>{item.schPmt.remaining.principal.format2()}</b></TableCell>
                        <TableCell align="right"><b>{item.schPmt.remaining.interest.format2()}</b></TableCell>
                        <TableCell align="right"><b>{item.schPmt.remaining.charges.format2()}</b></TableCell>
                        <TableCell align="right"><b>{item.schPmt.remaining.suspense.format2()}</b></TableCell>
                      </TableRow>
                    }
                  </React.Fragment>
                );
              }
              else {
                const firstPmt = item.payments[0];
                return (
                  <React.Fragment key={index} >
                    <TableRow key={index} style={{backgroundColor: backgroundColor, cursor: 'pointer'}} hover={true} 
                                              onClick={() => navigate('../payments/' + firstPmt.paymentId)} >
                      <TableCell align="left" rowSpan={item.payments.length} style={{borderTop: '2px solid black'}} ><i>{toMMDDYYYY(item.asOfDate)}</i></TableCell>
                      <TableCell align="left" rowSpan={item.payments.length} style={{borderTop: '2px solid black'}} ><i>Unscheduled</i></TableCell>
                      <TableCell align="left" style={{borderTop: '2px solid black'}} ><i>{toMMDDYYYY(firstPmt.receivedDate)}</i></TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} ><i>{firstPmt.principal.format2()}</i></TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} ><i>{firstPmt.interest.format2()}</i></TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} ><i>{firstPmt.charges.format2()}</i></TableCell>
                      <TableCell align="right" style={{borderTop: '2px solid black'}} ><i>{firstPmt.suspense.format2()}</i></TableCell>
                    </TableRow>
                    { item.payments.length > 0 &&
                      item.payments.filter((p,index) => index > 0).map( (p, paymentId) => 
                        <TableRow key={index} style={{backgroundColor: backgroundColor, cursor: 'pointer'}} hover={true}
                                              onClick={() => navigate('../payments/' + p.paymentId)} >
                          <TableCell align="left">{toMMDDYYYY(p.receivedDate)}</TableCell>
                          <TableCell align="right"><b>{p.principal.format2()}</b></TableCell>
                          <TableCell align="right"><b>{p.interest.format2()}</b></TableCell>
                          <TableCell align="right"><b>{p.charges.format2()}</b></TableCell>
                          <TableCell align="right"><b>{p.suspense.format2()}</b></TableCell>
                        </TableRow>
                      )
                    }
                  </React.Fragment>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>

    </Frame>
  );
}

