// React
import React, { } from 'react';
// Material UI
import { CircularProgress } from '@material-ui/core';

const LoadingOverlay = ({ open }) => {

	return ( 
	    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 64 }}>
	      <CircularProgress color="inherit" />
	    </div>
	);
}

export default LoadingOverlay;
