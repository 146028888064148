// React
import React, { useState } from 'react';
// Material UI
import { Grid, Table, Paper, TableHead, TableRow, TableCell, TableBody, TableContainer, Button, MenuItem, Typography } from '@material-ui/core';
// Other Thrid-Party
// Local
import Frame from '../layout/Frame';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import { AddButton, PopupMenuButton } from '../controls';
import { DetailSection, DetailSectionField } from '../layout/DetailSection';
import { toMMDDYYYY } from '../Util';

import DeleteConfirmDialog from './DeleteConfirmDialog';



export default function Loan({loanId, loan, loanBL, setLoan, saveLoan, deleteLoan, currentUserIsOwner, navigate}) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const toggleArchive = () => {
    const newLoan = loan.clone();
    newLoan.isArchived = ! newLoan.isArchived;
    saveLoan(newLoan);
  }


  if( loan === undefined )
    return (
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
        <div>Loading...</div>
      </Frame>
    );
  if( loan === 'not-found' )
    return ( 
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
        <div>Not found</div>
      </Frame>
    );

  const handleDeleteLoan = () => {
    deleteLoan(loanId).then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Error deleting document: ', error);
    });
  }

  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;

  const activity = loan.advances
          .map( (a,index) => ({ date: a.asOfDate, description: '$' + a.principal.format2() + ' Loan Advance', amount: a.cash(), url: 'advances/' + a.advanceId }) )
          .concat( loan.payments.map( (p,index) => ({ date: p.receivedDate, description: 'Payment', amount: -p.cash, url: '/loans/' + loanId + '/payments/' + p.paymentId }) ) )
          .concat( loan.charges.map( (c,index) => ({ date: c.asOfDate, description: '$' + c.amount.toLocaleString(undefined, {minimumFractionDigits: 2}) + ' ' + (c.description || 'fee'), url: '/loans/' + loanId + '/charges/' + c.chargeId }) ) )
          .concat( loan.refunds.map( (r,index) => ({ date: r.asOfDate, description: '$' + r.total() + ' Refund', amount: r.total(), url: '/loans/' + loanId + '/refunds/' + r.refundId }) ) )
          .concat( loan.writeoffs.map( (w,index) => ({ date: w.asOfDate, description: '$' + w.total() + ' Writeoff', url: '/loans/' + loanId + '/writeoffs/' + w.writeoffId }) ) )
          .sort((a,b) => a.date - b.date );

  return (
    <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
      <Grid container spacing={3} style={{ margin: 0 }} >
        
        { loan.isArchived &&
          <Grid item xs={12} >
            <Typography variant='body1' style={{textAlign:'center', color:'red', fontStyle: 'italic'}} >Loan Archived</Typography>
          </Grid>
        }

        <Grid item md={6} xs={12} >
          <DetailSection title='Basics' style={{height: '100%'}}
                    headerRight={
                      <Button onClick={() => navigate('terms')} >Terms</Button>
                    } >
            <DetailSectionField label='Borrower(s)' value={loan.borrowers} />
            <DetailSectionField label='Property' value={loan.property} />
            <DetailSectionField label='Loan Amount' value={loan.loanAmount.format2()} />
            <DetailSectionField label='Maturity' value={toMMDDYYYY(loan.maturityDate)} />
          </DetailSection>
        </Grid>
        
        <Grid item md={6} xs={12} >
          <DetailSection title='Status' style={{height: '100%'}}
                    headerRight={
                      <PopupMenuButton label='More' >
                        <MenuItem onClick={() => navigate('/loans/' + loanId + '/InterestAccrualDetail')} >Interest Accruals</MenuItem>
                        <MenuItem onClick={() => navigate('/loans/' + loanId + '/PaymentsDetail')} >Payments</MenuItem>
                        <MenuItem onClick={() => navigate('/loans/' + loanId + '/defaults')} >Defaults</MenuItem>
                        <MenuItem onClick={() => navigate('/loans/' + loanId + '/sharing')} disabled={isReadOnly} >Sharing</MenuItem>
                        <MenuItem onClick={() => navigate('/loans/' + loanId + '/payoff')} disabled={isReadOnly} >Payoff</MenuItem>
                        <MenuItem onClick={() => toggleArchive()} >{loan.isArchived ? 'Unarchive' : 'Archive'} Loan</MenuItem>
                        <MenuItem onClick={() => setDeleteConfirmOpen(true)} disabled={isReadOnly} >Delete Loan</MenuItem>
                      </PopupMenuButton>
                    } >
            <DetailSectionField label='Status' value={loanBL.status} />
            <DetailSectionField label='Next Due' value={loanBL.nextDue ? (loanBL.nextDue.remaining.total).format2() + ' on ' + toMMDDYYYY(loanBL.nextDue.paymentDate) : 'none'} />
            <DetailSectionField label='Principal' value={loanBL.currentBalance.principal.format2()} />
            <DetailSectionField label='Payoff' value={loanBL.currentBalance.total.format2()} />
          </DetailSection>
        </Grid>

        <Grid item xs={12} >
          <SectionHeaderRow text='Activity'
                            right={
                                <div>
                                  <AddButton label='Advance' onAdd={() => navigate('advances/new')} style={{marginRight: 20}}
                                    disabled={isReadOnly} />
                                  <AddButton label='Payment' onAdd={() => navigate('/loans/' + loanId + '/payments/new')} style={{marginRight: 20}}
                                    disabled={isReadOnly} />
                                  <AddButton label='Charge' onAdd={() => navigate('/loans/' + loanId + '/charges/new')} style={{marginRight: 20}}
                                    disabled={isReadOnly} />
                                  <AddButton label='Refund' onAdd={() => navigate('/loans/' + loanId + '/refunds/new')} style={{marginRight: 20}}
                                    disabled={isReadOnly} />
                                  <AddButton label='Writeoff' onAdd={() => navigate('/loans/' + loanId + '/writeoffs/new')} style={{marginRight: 20}}
                                    disabled={isReadOnly} />
                                </div>
                              } />
          <TableContainer component={Paper} >
            <Table style={{minWidth: 650}} >
              <TableHead>
                <TableRow >
                  <TableCell align="left" >Date</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { activity.length === 0 ?
                  <TableRow >
                    <TableCell align="center" colSpan='3' >No activty</TableCell>
                  </TableRow>
                  : activity.map((t, index) => {
                  return (
                    <TableRow key={index} hover={true} onClick={() => navigate(t.url)} style={{cursor: 'pointer'}} >
                      <TableCell align="left" >{toMMDDYYYY(t.date)}</TableCell>
                      <TableCell align="left">{t.description}</TableCell>
                      <TableCell align="right">{t.amount && t.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                    </TableRow> );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        
      </Grid>
      <DeleteConfirmDialog
        title="Delete Loan"
        open={deleteConfirmOpen}
        setOpen={setDeleteConfirmOpen}
        onConfirm={() => handleDeleteLoan()}
        message="Are you sure you want to delete this loan?"
      />
    </Frame>
  );
}

