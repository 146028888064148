// React
import React from 'react';
// Material UI
// Local
import SectionHeaderRow from './SectionHeaderRow';
import { DeleteButton, EditButton } from '../controls';
import LoadingCard from './LoadingCard';
import CardItem from './CardItem';


export const CardListSection = ({title, headerRight, getCardTitle, getCardUrl, getCardRight, onCardEdit, onCardDelete, isLoading, values, getIsDisabled, ...otherProps}) => {

  return (
    <div {...otherProps} >
       <SectionHeaderRow 
              text={title}
              right={headerRight} />
        <div>
          { values === undefined ?
            <LoadingCard />
            : values.length === 0 ?
            <div>None found.</div>
            : values.map( value =>
                <CardItem
                  key={value.id}
                  title={getCardTitle && getCardTitle(value)}
                  url={getCardUrl && getCardUrl(value)}
                  right={
                    <React.Fragment>
                      { getCardRight && getCardRight(value)}
                      {(onCardEdit || onCardDelete) && <div style={{width: '40px'}}/> }
                      {onCardEdit && <EditButton onEdit={(e) => onCardEdit(value)} style={{paddingTop:0, paddingBottom:0, paddingLeft: 12}} />}
                      {onCardDelete && <DeleteButton onDelete={() => onCardDelete(value)} style={{paddingTop:0, paddingBottom:0, paddingLeft: 12}} />}
                     </React.Fragment> }
                  disabled={getIsDisabled && getIsDisabled(value)}  /> 
            )
          }
        </div>
    </div>
  );

}


export default CardListSection;
