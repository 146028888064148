// React
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));


export const SelectField = (props) => {
  const {
    label,
    value,
    onValueChange,
    options,       // options: [ {label: 'Red', value: 'red' } ]
    disabled,
    className: classNameProp,
    ...otherProps
  } = props;

  const classes = useStyles();

  const handleOnChange = (event) => {
    event.preventDefault();
    if( onValueChange )
      onValueChange(event.target.value);
  }

  if( typeof value === 'number' )
    throw new Error('Property "value" is of type number.  Please use a string.');

  return (
    <FormControl className={clsx(classes.formControl, classNameProp)} {...otherProps} >
      {label && <InputLabel shrink={value !== ''} filled >{label}</InputLabel>}
      <Select     value={value}
                  onChange={(event) => handleOnChange(event)}
                  disabled={disabled}
                  > 
      {
        options.map( (option) => {
          if( typeof option.value === 'number' )
            throw new Error('One of the options values is type number.  Please use strings.');
          return ( <MenuItem key={option.value} value={option.value} >{option.label}</MenuItem> );
        } )
      }
      </Select>
    </FormControl>
  );
}








