// React
import React from 'react';
import ReactDOM from 'react-dom';
// Material UI
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// Other
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// Local
import theme from './theme';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils}>
    	<ThemeProvider theme={theme}>
	    	<CssBaseline />
	    	<App />
   		</ThemeProvider>
   	</MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
