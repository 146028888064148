// React
import React from 'react';
// Material UI
import { Button, Menu, IconButton } from '@material-ui/core';

export const PopupMenuButton = ({children, label, icon}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  // https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children
  const updatedChildren = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onMouseUp: () => setAnchorEl(null)} );
    }
    return child;
  });



  return (
    <div>
      { icon ? <IconButton onClick={handleClick} style={{color: 'white'}} >{icon}</IconButton>
        : label ? <Button onClick={handleClick}>{label}</Button>
        : <div/>
      }
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {updatedChildren}
      </Menu>
    </div>


  );

}


export default PopupMenuButton;
