import { isDate, day, addMonths, subMonths, round2Decimals, subDays, addDays, today, firstOfMonth, sameMonthAndYear, maxDate } from './Util';


export const calcInterest = ( dayCount, startDate, endDate, principal, rate ) => {
  if( dayCount === 'act_365' )
    return calcInterestAct365( startDate, endDate, principal, rate );
  else if( dayCount === '30_360' )
    return calcInterest30360( startDate, endDate, principal, rate );
  else
    throw new Error('Unsupported day count', dayCount);
}
const calcInterestAct365 = ( startDate, endDate, principal, rate ) => {
  return principal * (rate/100) * (subDays(endDate, startDate)+1) / 365;
}
const calcInterest30360 = ( startDate, endDate, principal, rate ) => {
  if( sameMonthAndYear(startDate, endDate) ) {
    const endDay = day(addDays(endDate, 1)) === 1 ? 30 : day(endDate);
    const int = principal * (rate/100) * ( endDay - day(startDate) + 1) / 360;
    //console.log( 'calcInterest30360', startDate, endDate, int );
    return int;
  }
  else {
    const startInt = principal * (rate/100) * (30 - day(startDate) + 1) / 360;
    const fullMonths = subMonths( firstOfMonth(endDate), addMonths(firstOfMonth(startDate), 1) );
    const mainInt = principal * (rate/100) * fullMonths / 12;
    const endInt = principal * (rate/100) * day(endDate) / 360;

    //console.log( 'calcInterest30360', startDate, endDate, startInt, mainInt, endInt );
    //console.log( '                 ', startDate, endDate, startInt, fullMonths, mainInt, endInt );

    return startInt + mainInt + endInt;
  }
}




const calcAccruals = (loan) => {
  // Make an array of all the accrual period end dates plus the closing date and maturity date
  let schAccStartDates = [ loan.closingDate ];
  if( loan.paymentSchedule === 'monthly' ) {
    schAccStartDates.push( addMonths(loan.firstPaymentDate, -1) );
    let dt = loan.firstPaymentDate;
    const mxDate = maxDate(loan.maturityDate, today());
    for( ; dt <= mxDate; dt = addMonths(dt,1) )
      schAccStartDates.push(dt);
  }
  if( today() > loan.maturityDate )
    schAccStartDates.push(today());

  // Get a list of all transactions that change the principal
  const txns = loan.transactions().filter( t => t.principal !== 0 )
                .concat( { asOfDate: loan.closingDate, principal: 0 } );
  // Group the transactions by date
  const groupedTxns = txns.groupAndSum('asOfDate', ['principal']);
  // Convert the principal into principal balances
  let prinBal = 0;
  const principalBalances = groupedTxns
                 .sortBy('asOfDate')
                 .map( (t) => {
    prinBal = prinBal + t.principal;
    t.principal = prinBal;
    return t;
  });

  // Make an array of all rate changes
  const minDate = principalBalances[0].asOfDate < loan.closingDate ? principalBalances[0].asOfDate : loan.closingDate;
  const rateChanges = [ { asOfDate: minDate, rate: loan.interestRate } ]
                      .concat( loan.defaults.map( d => ({ asOfDate: d.defaultDate, rate: loan.defaultInterestRate }) ) )
                      .concat( loan.defaults.filter( d => isDate(d.cureDate) )
                                            .map( d => ({ asOfDate: d.cureDate, rate: loan.interestRate }) ) )
                      .sortBy('asOfDate');
  //console.log('calcAccruals', 'rateChanges', rateChanges);

  // Make an array containing all unique dates from the above, and the principal and rate as of those dates
  const sorted = schAccStartDates.map( d =>  ({ asOfDate: d }) )
            .concat( [{ asOfDate: loan.maturityDate }] )
            .concat( principalBalances )
            .concat( rateChanges )
            .sortBy('asOfDate');

  const merged = sorted.reduce( (grps,item) => {
                  const lastGroup = grps[grps.length-1];
                  if( lastGroup.asOfDate === item.asOfDate ) {
                    if( item.principal !== undefined )
                      lastGroup.principal = item.principal;
                    if( item.rate !== undefined )
                      lastGroup.rate = item.rate;
                  }
                  else {
                    grps.push( Object.assign( {}, lastGroup, item ) );
                  }
                  return grps;
                }, [ { asOfDate: minDate, principal: 0 } ] );

  // Calculate interest
  const accruals = merged
        .filter( (m,index) => index !== merged.length - 1 )     // Remove the last one - it will be the end date of the final period
        .map( (m,index) => {
    const p = {
      from: m.asOfDate,
      to: addDays(merged[index+1].asOfDate, -1),
      principal: m.principal,
      rate: m.rate
    }
    p.interestAccrued = calcInterest( loan.dayCount, p.from, p.to, p.principal, p.rate);
    return p;
  });

  return accruals;        
}

const calcRegularDue = (loan, interestAccrued, paymentDate) => {
    
    if( loan.paymentAmount === 'interest' )
        return {
          principal: 0,
          interest: interestAccrued,
          charges: 0,
          suspense: 0
        };
    else if( loan.paymentAmount === 'fixed' && loan.applyFixedPaymentTo === 'int-only' ) {
        return {
          principal: 0,
          interest: loan.paymentAmountFixed,
          charges: 0,
          suspense: 0
        };
    }
    else if( loan.paymentAmount === 'fixed' && loan.applyFixedPaymentTo === 'int-then-prin' ) {
        const int = Math.min(loan.paymentAmountFixed, interestAccrued);
        return {
          principal: loan.paymentAmountFixed > int ? loan.paymentAmountFixed - int : 0,
          interest: int,
          charges: 0,
          suspense: 0
        };
    }
    return undefined;
}

const calcDues = (loan, intAccruals) => {
    const schPaymentDates = loan.getScheduledPaymentDates();
    const lastSchPaymentDate = maxDate(...schPaymentDates);
    const firstDate = loan.transactions().length === 0 ? loan.closingDate : Math.min( loan.transactions().min('asOfDate'), loan.closingDate).toString();
    const lastDate = loan.transactions().length === 0 ? loan.maturityDate : maxDate( loan.transactions().max('asOfDate'), loan.maturityDate, today(), lastSchPaymentDate).toString();

    let accStart = firstDate;
    const runningBalance = { principal: 0, interest: 0, charges: 0, suspense: 0 };
    const hist = [];
    for( let dt = firstDate; dt <= lastDate; dt = addDays(dt, 1) ) {
        const isRegularDueDate = schPaymentDates.includes(dt);

        const advTotals = loan.transactions().filter( t => t.asOfDate === dt && t.type === 'advance' ).sum(['principal', 'interest', 'charges', 'suspense']);
        const refTotals = loan.transactions().filter( t => t.asOfDate === dt && t.type === 'refund' ).sum(['principal', 'interest', 'charges', 'suspense']);
        const chgTotalImmediate = loan.allCharges().filter( c => c.asOfDate === dt && c.due === 'immediately' ).sum('charges');
        const chgTotalTermination = loan.allCharges().filter( c => c.asOfDate === dt && c.due === 'termination' ).sum('charges');
        const pmtTotalsUnsch = loan.allPayments().filter( p => p.asOfDate === dt && p.type === 'unscheduled' ).sum(['principal', 'interest', 'charges', 'suspense']);
        const pmtTotalsSch = loan.allPayments().filter( p => p.asOfDate === dt && p.type === 'scheduled' ).sum(['principal', 'interest', 'charges', 'suspense']);

        if( loan.paymentSchedule === 'monthly' && dt === addMonths(loan.firstPaymentDate,-1) ) {
          const prepaidInterestAccured = intAccruals.filter( a => a.from >= accStart && a.to < dt ).sum('interestAccrued');  // eslint-disable-line no-loop-func
          accStart = dt;
          runningBalance.interest = round2Decimals( runningBalance.interest + prepaidInterestAccured );
        }

        // Get any prepaid interest due
        const prepaidInterestDue = loan.advances.filter( a => a.asOfDate === dt && a.prepaidInterestDue === 'immediately' ).sum('prepaidInterest');

        if( isRegularDueDate || chgTotalImmediate > 0 || prepaidInterestDue > 0 ) {
        
            // Accrue interest up through the previous day
            let interestAccrued = 0;
            if( isRegularDueDate ) {
                const accEnd = addDays(dt, -1);
                interestAccrued = intAccruals.filter( a => a.from >= accStart && a.to <= accEnd ).sum('interestAccrued');  // eslint-disable-line no-loop-func
                accStart = dt;
            }

            // Add the charges and interest to the running balance
            runningBalance.interest = runningBalance.interest + interestAccrued;
            runningBalance.charges = runningBalance.charges + chgTotalImmediate + chgTotalTermination;

            // Calculate Due
            const regDue = isRegularDueDate ? calcRegularDue(loan, interestAccrued, dt) : { principal: 0, interest: 0, charges: 0, suspense: 0 };
            const due = {
                principal: Math.max( Math.min( regDue.principal, runningBalance.principal ), 0).round2(),
                interest: (Math.max( Math.min( regDue.interest, runningBalance.interest ), 0) + prepaidInterestDue).round2(),
                charges: Math.max( Math.min( regDue.charges + chgTotalImmediate, runningBalance.charges ), 0).round2(),
                suspense: Math.max( Math.min( regDue.suspense, runningBalance.suspense ), 0).round2()
            };
            due.total = round2Decimals( due.principal + due.interest + due.charges + due.suspense );

//            console.log('calcDues', dt, interestAccrued, regDue.interest, runningBalance.interest, prepaidInterestDue);

            // Calculate Paid
            const paid = {
                principal: -pmtTotalsSch.principal.round2(),
                interest: -pmtTotalsSch.interest.round2(),
                charges: -pmtTotalsSch.charges.round2(),
                suspense: -pmtTotalsSch.suspense.round2()
            }
            paid.total = round2Decimals( paid.principal + paid.interest + paid.charges + paid.suspense );

            // Calculate Remaining
            const remaining = {
              principal: round2Decimals( due.principal - paid.principal ),
              interest: round2Decimals( due.interest - paid.interest ),
              charges: round2Decimals( due.charges - paid.charges ),
              suspense: round2Decimals( due.suspense - paid.suspense ),
              total: round2Decimals( due.total - paid.total ),
            };

            const status = remaining.principal === 0 && remaining.interest === 0 && remaining.charges === 0 && remaining.suspense === 0 ? 'Paid' 
                          : remaining.principal < 0 || remaining.interest < 0 || remaining.charges < 0 || remaining.suspense < 0 ? 'Overpaid'
                          : dt === today() ? 'Due Today'
                          : dt < today() ? 'Late' : 'Future';

            // Add to history
            const histItem = {
              paymentDate: dt,
              due: due,
              paid: paid,
              remaining: remaining,
              status: status,
            }
            hist.push(histItem);

            // Add the dues and remaining transactions to the running balance (except schedule pmts)
            runningBalance.principal = runningBalance.principal - due.principal + advTotals.principal + refTotals.principal + pmtTotalsUnsch.principal;
            runningBalance.interest = runningBalance.interest - due.interest + advTotals.interest + refTotals.interest + pmtTotalsUnsch.interest;
            runningBalance.charges = runningBalance.charges - due.charges + advTotals.charges + refTotals.charges + pmtTotalsUnsch.charges;
            runningBalance.suspense = runningBalance.suspense - due.suspense + advTotals.suspense + refTotals.suspense + pmtTotalsUnsch.suspense;

        }
        else {
            const txnTotals = loan.transactions()
                .filter( t => t.asOfDate === dt && ['advance', 'payment', 'refund'].includes(t.type) )
                .sum(['principal', 'interest', 'charges', 'suspense']);
            runningBalance.principal = runningBalance.principal + txnTotals.principal;
            runningBalance.interest = runningBalance.interest + txnTotals.interest;
            runningBalance.charges = runningBalance.charges + txnTotals.charges + chgTotalImmediate + chgTotalTermination;
            runningBalance.suspense = runningBalance.suspense + txnTotals.suspense;

        }
    }  

    return hist;
}

const calcBalances = (loan, intAccruals, asOfDate, includeAllTxns) => {
  if( includeAllTxns === undefined )
    includeAllTxns = false;

  const txns = includeAllTxns ?  
          loan.transactions()
              .filter( t => ! ( t.type === 'charge' && t.due === 'net' ) )
        : loan.transactions()
              .filter( t => t.asOfDate <= asOfDate )
              .filter( t => ! ( t.type === 'charge' && t.due === 'net' ) );
  const txnTotals = txns.sum(['principal','interest','charges','suspense']);

  // Calculate full periods of interest
  const interestToDate = addDays(asOfDate, -1);
  const accIntFullPeriods = intAccruals.filter( i => i.to <= interestToDate ).sum('interestAccrued');
  const thisIntAccPeriod = intAccruals.find( i => interestToDate >= i.from && interestToDate <= i.to ) || intAccruals[intAccruals.length - 1];
  const partialMonthIntAcc = thisIntAccPeriod.to === interestToDate ? 0 : calcInterest( loan.dayCount, thisIntAccPeriod.from, addDays(asOfDate,-1), thisIntAccPeriod.principal, thisIntAccPeriod.rate );

  const curBalances = {
    asOfDate: asOfDate,
    principal: round2Decimals( txnTotals.principal ),
    interest: round2Decimals( txnTotals.interest + accIntFullPeriods + partialMonthIntAcc ),
    charges: round2Decimals( txnTotals.charges ),
    suspense: round2Decimals( txnTotals.suspense ),
    partialMonthAccrualDetail: {
      from: thisIntAccPeriod.from,
      to: addDays(asOfDate,-1),
      principal: thisIntAccPeriod.principal,
      rate: thisIntAccPeriod.rate,
      interest: partialMonthIntAcc
    }
  };
  curBalances.total = round2Decimals( curBalances.principal + curBalances.interest + curBalances.charges - curBalances.suspense );

  return curBalances;
}

const LoanBLTemplate = {
  getPayoff: function(loan, payoffDate) { 
    const balances = calcBalances( loan, this.interestAccruals, payoffDate, true );
    const dailyInterest = calcInterest( loan.dayCount, payoffDate, payoffDate, balances.principal, balances.partialMonthAccrualDetail.rate );
    return { payoffDate: payoffDate, principal: balances.principal, interest: balances.interest, charges: balances.charges, suspense: balances.suspense, total: balances.total, dailyInterest: dailyInterest };
  },

  calcPrepaidInterest: function(loan, asOfDate, principal) {
    if( asOfDate === undefined || principal === undefined )
      return undefined;

    const prepayEnd = addDays(addMonths( loan.getScheduledPaymentDates().find( d => d > asOfDate ), -1), -1);
    const intAccPeriods = this.interestAccruals.filter( i => i.from >= asOfDate && i.to <= prepayEnd );
    const prepaidInterests = intAccPeriods.map( p => {
      const startDate = asOfDate > p.from ? asOfDate : p.from;
      return calcInterest( loan.dayCount, startDate, p.to, principal, p.rate );
    });
    const prepaidInterest = prepaidInterests.sum();
    
    return prepaidInterest;
  }
};

export const createLoanBL = (loan) => {

	// Get draft interest accruals
	const intAccruals = calcAccruals(loan);

	// Calculate current balances
  const currentBalance = calcBalances(loan, intAccruals, today(), true);

  // Calculate if paid off
  const isPaidOff = loan.advances.length > 0 && currentBalance.principal === 0 && currentBalance.interest === 0 && currentBalance.charges === 0 && currentBalance.suspense === 0;
  const payOffDate = isPaidOff ? Math.max( loan.payments.max('asOfDate'), loan.advances.max('asOfDate'), loan.charges.max('asOfDate') ) : undefined;

  // Remove any interest accruals that start after the payoff date
  if( isPaidOff )
    for( let i=intAccruals.length - 1; intAccruals[i].from > payOffDate; i-- )
    	intAccruals.pop();

	// Calculate scheduled payments and their statuses
  const paymentHistory = calcDues(loan, intAccruals);

  const nextDue = isPaidOff ? undefined : paymentHistory.sortBy('paymentDate').find( p => p.status === 'Late' || p.status === 'Due Today' || p.status === 'Future' );
  let status =
      loan.advances.length === 0 ? 'Not Funded' :
      currentBalance.principal === 0 && currentBalance.interest === 0 && currentBalance.charges === 0 && currentBalance.suspense === 0 ? 'Paid-Off' :
      currentBalance.total === 0 && ( currentBalance.principal !== 0 || currentBalance.interest !== 0 || currentBalance.charges !== 0 || currentBalance.suspense !== 0  ) ? 'Needs True-up' :
      currentBalance.total < 0 ? 'Needs Refund' :
      loan.paymentSchedule === 'none' ? 'Current' :
      nextDue === undefined ? 'Current' :
      nextDue.status === 'Future' ? 'Current' :
      nextDue.status;
  if( ! ['Not Funded','Paid-Off'].includes(status) && loan.getDefaultStatus() === 'In Default' )
    status = status + '; In Default';

	// Return result object
  const loanBL = Object.assign( {}, LoanBLTemplate, {
  	interestAccruals: intAccruals,
  	currentBalance: currentBalance,
  	isPaidOff: isPaidOff,
  	payOffDate: payOffDate,
  	scheduledPayments: paymentHistory,
  	status: status,
  	nextDue: nextDue
  });
//  console.log('createLoanBL', 'loanBL', loanBL);

  return loanBL;
}