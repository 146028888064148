// React
import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';


const styles = (theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  }

});
const useStyles = makeStyles(styles);


export const SectionHeaderRow = ({text, right}) => {
  const classes = useStyles();

  return (
        <div className={classes.container}>
          <Typography variant='h3' >{text}</Typography>
          <div>{right}</div>
        </div>
  );
}

export default SectionHeaderRow;
