
import moment from 'moment';


export const setDeep = (obj, dotPath, value) => {
    const pathParts = dotPath.split('.');
    let tempObj = obj;
    pathParts.forEach( (part, index, arr) => {
      if( index === arr.length - 1 )
        tempObj[part] = value;
      else {
        if( tempObj[part] === undefined )
          tempObj[part] = {};
        tempObj = tempObj[part];
      }
    });
}

// Date Functions
const toMoment = (d) => {
  const m = moment(d, 'YYYYMMDD', true);
  if( ! m.isValid() )
    throw new Error('Invalid date:', d);
  return m;
}
export const isDate = (d) => {
  if( d === undefined || d === null || d === '' ) return false;
  return moment(d, 'YYYYMMDD') !== undefined;
}
export const addMonths = (d, n) => {
  return toMoment(d).add(n, 'months').format('YYYYMMDD');
}
export const addDays = (d, n) => {
  return toMoment(d).add(n, 'days').format('YYYYMMDD');
}
export const setDay = (d, n) => {
  return toMoment(d).date(n).format('YYYYMMDD');
}
export const subMonths = (a, b) => {
  return toMoment(a).diff(toMoment(b), 'months');
}
export const subDays = (a, b) => {
  return toMoment(a).diff(toMoment(b), 'days');
}
export const day = (a) => {
  return toMoment(a).date();
}
export const today = () => {
  return moment().format('YYYYMMDD');
}
export const firstOfMonth = (a) => {
  return a.substr(0, 6) + '01';
}
export const sameMonthAndYear = (a,b) => {
  return a.substr(0, 6) === b.substr(0, 6);
}
export const toMMDDYYYY = (d) => {
  if( d === undefined || d === null || d === '' )
    return d;
  return toMoment(d).format('MM/DD/YYYY');
}
export const maxDate = (...args) => {
  const asNumbers = args.map(a => Number(a));
  const mx = Math.max( ...asNumbers );
  const asString = String(mx);
  //console.log('maxDate', args, asNumbers, mx, asString);
  return asString;
}


export const unique = (arr) => {
  return [...new Set(arr)];
}


export const round2Decimals = (v) => {
  return Math.round(v * 100) / 100;
}
export const round4Decimals = (v) => {
  return Math.round(v * 100) / 100;
}


// eslint-disable-next-line no-extend-native
Array.prototype.last = function() {
  return this[this.length-1];
}
// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function(property) {
  return this.sort( (a,b) => a[property] - b[property] );
}
// eslint-disable-next-line no-extend-native
Array.prototype.groupAndSum = function(groupBy, sumArr) {
  return this.reduce( (grps,t) => {
     let grp = grps.find( s => s[groupBy] === t[groupBy] )
     if( grp === undefined ) {
       grp = { [groupBy]: t[groupBy] };
       grps.push( grp );
     }

     sumArr.forEach( s => {
       grp[s] = (grp[s] || 0) + t[s];
     } );

     return grps;
  }, [])
}
// eslint-disable-next-line no-extend-native
Array.prototype.sum = function(fields) {
  if( fields === undefined ) {
    return this.reduce( (s,i) => s + i, 0 );
  }
  else {
    if( ! Array.isArray(fields) )
      fields = [fields];
    // Create initial value
    const start = {};
    fields.forEach( f => { start[f] = 0 } );
    // Calculate sums
    const sums = this.reduce( (s,i) => {
      fields.forEach( f => { s[f] = s[f] + (i[f] === undefined ? 0 : i[f]) } );
      return s;
    }, start);
    
    return fields.length === 1 ? sums[fields[0]] : sums;
  }
}
// eslint-disable-next-line no-extend-native
Array.prototype.min = function(field) {
  if( this.length === 0 )
    return undefined;
  return this.reduce( (m,v) => v[field] < m ? v[field] : m, this[0][field] );
}
// eslint-disable-next-line no-extend-native
Array.prototype.max = function(field) {
  if( this.length === 0 )
    return undefined;
  return this.reduce( (m,v) => v[field] > m ? v[field] : m, this[0][field] );
}
// eslint-disable-next-line no-extend-native
Array.prototype.group = function(field) {
  const objGrps = this.reduce( (objGrps,i) => {
    const value = i[field];
    if( objGrps[value] === undefined )
      objGrps[value] = [];
    objGrps[value].push(i);
    return objGrps;
  }, {});
  return Object.keys(objGrps).map( k => ({ [field]: k, items: objGrps[k] }) );
}

// eslint-disable-next-line no-extend-native
Number.prototype.format2 = function() {
  return this.toLocaleString(undefined, {minimumFractionDigits: 2})
}
// eslint-disable-next-line no-extend-native
Number.prototype.round2 = function() {
  return Math.round(this * 100) / 100;
}


export const isValidEmailAddress = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}





