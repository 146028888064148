// React
import React, { useState, useEffect } from 'react';
// Material UI
import { MyTextField } from '.';



const allowSkip = true;
const breakAfter = [2,4];
const breakChar = '/';
const skipChar = '0';
export const DateTextField = ({value, onValueChange, errorText, ...otherProps}) => {

  const [refInput, setRefInput] = useState(undefined);
  const [val, setVal] = useState('');
  const [selStart, setSelStart] = useState(null);

  
  useEffect(() => {
    setVal(value || '');
  }, [value]);


  useEffect(() => {
    if( ! refInput )
      return;

    refInput.onkeydown = (e) => {
      const key = e.key;
      const curValue = e.target.value;
      const selStart = e.target.selectionStart;
      const selEnd = e.target.selectionEnd;

      if( ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab"].includes(key) )
        return true;
      else if( "0123456789".indexOf(key) >= 0 )
        return true;
      else if( key === "Backspace" ) {
        // If deleted a dash, also delete character before it
        const charBefore = curValue.substr(selStart-1, 1);
        if( charBefore === breakChar && selStart === selEnd ) {
          e.target.selectionStart = selStart - 2;
          e.target.selectionEnd = selEnd;
        }

        return true;
      }
      else if( key === "a" && e.metaKey === true )
        return true;
      else if( key === breakChar && allowSkip ) {
        // If we are a single character before the next break, allow skipping to the break; prepend a character to this segment
        if( selStart === breakAfter[0] - 1 ) {
          e.target.value = skipChar + e.target.value;
          return true;
        }
        for( let i=0; i<breakAfter.length-1; i++ ) {
          if( selStart === breakAfter[i+1] + i ) {
            e.target.value = e.target.value.substring(0, breakAfter[i]) + skipChar + e.target.value.substring(breakAfter[i]);
            return true;
          }
        }
      }
      return false;
    };
  },[refInput]);

  if( refInput ) {
      refInput.selectionStart = selStart;
      refInput.selectionEnd = refInput.selectionStart;
  }

  const handleOnChange = (event) => {
    let targetValue = event.target.value;
    let pos = event.target.selectionStart;

    // Remove all breaks
    targetValue = targetValue.replace( new RegExp(breakChar, 'g'), '');
    //console.log(targetValue.length)
    if( targetValue.length > 8 )
      return;

    // Add back breakChars in the correct places
    let newValue = targetValue.substring(0, breakAfter[0]);
    for( let i=0; i<breakAfter.length; i++ ) {
      if( breakAfter[i] > targetValue.length )
        break;

      let section = targetValue.substring( breakAfter[i], breakAfter[i+1] );
      newValue = newValue + breakChar + section;
    }

    // Determine the cursor position after the updates
    let newPos = pos;
    for( let i=0; i<breakAfter.length; i++ ) {
      if( pos === breakAfter[i]+i )
        newPos = breakAfter[i] + 1 + i;
    }

    // Make updates
    setSelStart(newPos);
    setVal(newValue);

    if( onValueChange )
      onValueChange(newValue);
  };


  return (
    <MyTextField
      inputRef={ref => setRefInput(ref)}
      value={val}
      onChange={(event) => handleOnChange(event)}
      errorText={errorText}
      {...otherProps}
    />
  );
}

export default DateTextField;