import { isDate } from '../Util';
import { dueOptionsStandalone } from './Misc';

const chargeTemplate = {
  asOfDate: undefined,
  amount: 0,
  due: undefined,
  toPOJO: function() { return JSON.parse(JSON.stringify(this)) },
  clone: function() { return createCharge(this.id, this.toPOJO()) },

  isValid: function() {
      if( ! isDate(this.asOfDate) ) return false;
      if( isNaN(this.amount) ) return false;
      if( this.amount < 0 ) return false;
      if( ! dueOptionsStandalone.map(o => o.value).includes(this.due) ) return false;
      return true;
  }
};

export const createCharge = (id, data) => {
  return Object.assign( {}, chargeTemplate, data, { chargeId: id } );
}
