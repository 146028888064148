import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2b5190',
    },
    background: {
      default: '#f0f0f0',
    },
  },
  typography: {
    h1: {    // Signin Page Title
      fontSize: '2.125rem',
      fontWeight: 400,
    },
    h2: {    // Page top title
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h3: {    // Section titles
      fontSize: '1.125rem',
      fontWeight: 500,
      marginTop: 16,
      marginBottom: 16,
    },
    h4: {    // Card titles
      fontSize: '1.000rem',
      fontWeight: 500,
    },
    body1: {
      fontsize: '1.000rem',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiCard: {
      root: {

        //marginTop: '8px',
        marginBottom: '16px',
      },
    },
    MuiCardContent: {
      root: {
        paddingBottom: '16px',
        "&:last-child": {
          paddingBottom: '16px'
        }
      }
    },
    MuiDropzoneArea: {
      icon: {
        width: '36px',
        height: '36px',
      }
    }
  },
  props: {
    MuiCard: {
      elevation: 2,
    },
    MuiTextField: {
      variant: 'standard',
    },
  },
});

export default theme;