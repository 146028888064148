// React
import React, {useState, useEffect} from 'react';
// Material UI
import { Grid, TableContainer, Table, Paper, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
// Other Third-Party
// Local
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { DateField, AddButton } from '../controls';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import { DeleteButton } from '../controls';
import { setDeep } from '../Util';



export default function LoanDefaults({loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [defaults, setDefaults] = useState(undefined);

  useEffect(() => {
    if( loan === undefined )
      setDefaults(undefined);
    else if( loan === 'not-found' )
      setDefaults('not-found');
    else
      setDefaults(loan.defaults || []);
  }, [loan]);


  const handleCancel = () => {
    navigate('/loans/' + loanId);
  }
  const handleSave = () => {
    const newLoan = loan.clone();
    newLoan.defaults = JSON.parse(JSON.stringify(defaults));
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }

  const handleOnChange = (index, name, value) => {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    setDeep( newDefaults, index + '.' + name, value );
    setDefaults( newDefaults );
  };

  const handleOnAdd = () => {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    newDefaults.push({ defaultDate: undefined, cureDate: undefined });
    setDefaults( newDefaults );
  }

  const handleOnDelete = (index) => {
    const newDefaults = JSON.parse(JSON.stringify(defaults));
    newDefaults.splice(index,1);
    setDefaults( newDefaults );
  }

  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;

  if( defaults === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  if( defaults === 'not-found' )
    return ( 
      <SaveCancelDialog title='Not Found' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );

  return (
    <SaveCancelDialog title='Loan Defaults' onCancel={() => handleCancel()} onSave={() => handleSave()} disableSave={(!loan.validate().isValid) || isReadOnly} >
      <Grid container spacing={3} style={{ margin: 0 }} >


        <Grid item xs={12} >
          <SectionHeaderRow text='Defaults'
                            right={ <div><AddButton onAdd={() => handleOnAdd()} 
                                    disabled={isReadOnly} /></div> } />
          <TableContainer component={Paper} >
            <Table style={{minWidth: 650}} >
              <TableHead>
                <TableRow >
                  <TableCell align="left" >Default Date</TableCell>
                  <TableCell align="left">Cure Date</TableCell>
                  <TableCell align="left">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { defaults.length === 0 ?
                  <TableRow >
                    <TableCell align="center" colSpan='6' >No defaults</TableCell>
                  </TableRow>
                  : defaults
                        .sortBy('defaultDate')
                        .map( (a,index) =>
                            <TableRow key={index} >
                              <TableCell align="left" >
                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                  <DateField          
                                    value={a.defaultDate}
                                    onValueChange={(value) => handleOnChange(index, "defaultDate", value)}
                                    disabled={isReadOnly}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="left" >
                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                  <DateField          
                                    value={a.cureDate}
                                    onValueChange={(value) => handleOnChange(index, "cureDate", value)}
                                    disabled={isReadOnly}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                <DeleteButton onDelete={() => handleOnDelete(index)}
                                    disabled={isReadOnly} />
                              </TableCell>
                            </TableRow>
                        )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </SaveCancelDialog>
  );

}






