// React
import React from 'react';
// Material UI
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
// Other Thrid-Party
// Local
import Frame from '../layout/Frame';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import { toMMDDYYYY } from '../Util';


export default function InterestAccrualDetail({loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {

  if( loan === 'not-found' )
    return ( 
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
        <div>Not found</div>
      </Frame>
    );
  if( loan === undefined )
    return (
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/'} >
        <div>Loading...</div>
      </Frame>
    );

  return (
    <Frame title='Interest Accrual' backLabel={'Back'} backTo={-1} >
      <SectionHeaderRow text='Accruals' />
      <TableContainer component={Paper} >
        <Table style={{minWidth: 650}} >
          <TableHead>
            <TableRow>
              <TableCell align="left">Start</TableCell>
              <TableCell align="left">End</TableCell>
              <TableCell align="right">Principal</TableCell>
              <TableCell align="right">Rate</TableCell>
              <TableCell align="right">Interest</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loanBL.interestAccruals.map((a,index, arr) => 
              <TableRow key={index}>
                <TableCell align="left">{toMMDDYYYY(a.from)}</TableCell>
                <TableCell align="left">{toMMDDYYYY(a.to)}</TableCell>
                <TableCell align="right">{a.principal.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                <TableCell align="right">{a.rate.toLocaleString(undefined, {minimumFractionDigits: 3})}</TableCell>
                <TableCell align="right">{a.interestAccrued.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Frame>
  );
}

