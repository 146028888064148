// React
import React, { useState, useEffect } from 'react';
// Material UI
// Other Third-Party
// Local
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, CurrencyTextField, DateField, SelectField } from '../controls';
import { DetailSection } from '../layout/DetailSection';
import Form from '../Form';
import { setDeep } from '../Util';
import { createCharge, dueOptions } from '../model';




export default function Charge({chargeId, loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [charge, setCharge] = useState(undefined); 

  useEffect(() => {
    if( loan === undefined )
      setCharge(undefined);
    else if( loan === 'not-found' )
      setCharge('not-found');
    else if( chargeId === 'new' )
      setCharge(createCharge());
    else if( ! loan.charges[chargeId] )
      setCharge('not-found');
    else
      setCharge(loan.charges[chargeId]);
  }, [loan, chargeId]);

  const handleCancel = () => {
    navigate('/loans/' + loanId);
  }
  const handleSave = () => {
    const newLoan = loan.clone();
    if( chargeId === 'new' )
      newLoan.charges.push(charge);
    else
      newLoan.charges[chargeId] = charge;
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }
  const handleDelete = () => {
    const newLoan = loan.clone();
    newLoan.charges.splice(chargeId, 1);
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }

  const handleOnChange = (name, value) => {
    let newCharge = charge.clone();
    setDeep( newCharge, name, value );
    setCharge( newCharge );
  };

  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;


  if( charge === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  if( charge === 'not-found' )
    return ( 
      <SaveCancelDialog title='Not Found' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );

  return (
    <SaveCancelDialog title='Charge' 
                      onCancel={() => handleCancel()} 
                      onSave={() => handleSave()} 
                      onDelete={ chargeId !== 'new' && (!isReadOnly) && ( () => handleDelete() )}
                      disableSave={isReadOnly || (!charge.isValid()) } >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}  >
        <DetailSection title='Charge' >
          <Form disabled={isReadOnly}>
            <DateField          
              label="As Of"
              value={charge.asOfDate || ''}
              onValueChange={(value) => handleOnChange("asOfDate", value)}
            />
            <CurrencyTextField
              label="Amount"
              value={charge.amount}
              onValueChange={(value) => handleOnChange("amount", value)}
            />
            <SelectField
              label="Due"
              options={dueOptions.filter( o => o.value !== 'net' )}
              value={charge.due || ''}
              onValueChange={(value) => handleOnChange('due', value)}
            />
            <MyTextField
              label="Description"
              value={charge.description || ''}
              onValueChange={(value) => handleOnChange("description", value)}
            />
            <MyTextField
              label="Notes"
              value={charge.notes || ''}
              onValueChange={(value) => handleOnChange("notes", value)}
            />
          </Form>
        </DetailSection>
      </div>
    </SaveCancelDialog>
  );

}






