export const dueOptions = [
      	{ value: 'net', label: 'Net From Advance' }, 
      	{ value: 'immediately', label: 'Immediately' }, 
      	{ value: 'termination', label: 'At Termination'} ];

export const dueOptionsStandalone = dueOptions.filter( o => o.value !== 'net' );

export const dayCountOptions = [
      	{ value: 'act_365', label: 'Act/365' }, 
      	{value: '30_360', label: '30/360'} ];

export const paymentScheduleOptions = [
		{ value: 'monthly', label: 'Monthly' },
		{value:'none', label: 'None'} ];

export const paymentAmountOptions = [
		{ value: 'interest', label: 'Accrued Interest' },
		{value:'fixed', label: 'Fixed Amount'} ];

export const applyFixedPaymentToOptions = [
		{ value: 'int-then-prin', label: 'Interest then Pricipal' },
		{value:'int-only', label: 'Interest Only'} ];
