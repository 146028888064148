// React
import React, { useContext, useState, useEffect } from 'react';
// Reach Router
import { navigate as rootNavigate } from "@reach/router"
// Material UI
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Local
import AppContext from '../AppContext';
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, PercentTextField, CurrencyTextField, DateField, SelectField } from '../controls';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import Form from '../Form';
import { setDeep } from '../Util';
import { createLoan, dayCountOptions, paymentScheduleOptions, paymentAmountOptions, applyFixedPaymentToOptions } from '../model';




const styles = (theme) => ({
    container: {

      // Children
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },

      backgroundColor: '#FFFFFF',
    },
});
const useStyles = makeStyles(styles);


export default function LoanEdit({loanId, loan: parentLoan, loanBL, setLoan: setParentLoan, saveLoan, currentUserIsOwner: parentCurrentUserIsOwner, navigate}) {
  const {currentUser} = useContext(AppContext);
  const classes = useStyles();
  const [loan, setLoan] = useState(undefined)

  useEffect(() => {
    if( loanId === 'new' ) {
      const newLoan = createLoan();
      newLoan.ownerUID = currentUser.uid;
      setLoan(newLoan);
    }
    else if( parentLoan === undefined ) 
      setLoan(undefined);
    else if( parentLoan === 'not-found' )
      setLoan('not-found');
    else
      setLoan(parentLoan.clone());
  },[parentLoan, loanId, currentUser]);

  const handleCancel = () => {
    rootNavigate(-1);
  }
  const handleSave = () => {
    saveLoan(loan).then( loanId => navigate('/loans/' + loanId) );
  }

  const handleOnChange = (name, value) => {
    let newLoan = createLoan(JSON.parse(JSON.stringify(loan)));
    setDeep( newLoan, name, value );
    setLoan( newLoan );
  };


  if( loan === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  else if( loan === 'not-found' )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );
  else {

    const currentUserIsOwner = loanId === 'new' ? true : parentCurrentUserIsOwner;
    const validation = loan.validate();
    const isReadOnly = (!currentUserIsOwner) || loan.isArchived;

    return (
      <SaveCancelDialog title='Loan Terms' onCancel={() => handleCancel()} onSave={() => handleSave()} disableSave={(!validation.isValid) || isReadOnly } >
        <div className={classes.container} >
          <div className={classes.pageContent} >
            <Grid container spacing={3} >
              <Grid item md={6} xs={12} >
                <SectionHeaderRow text='Basics' />
                <Form disabled={isReadOnly} >
                  <MyTextField
                    label="Nickname"
                    value={loan.nickname || ''}
                    onValueChange={(value) => handleOnChange("nickname", value)}
                  />
                  <MyTextField
                    label="Borrower(s)"
                    value={loan.borrowers || ''}
                    onValueChange={(value) => handleOnChange("borrowers", value)}
                  />
                  <MyTextField
                    label="Property"
                    value={loan.property || ''}
                    onValueChange={(value) => handleOnChange("property", value)}
                  />
                  <DateField    
                    label="Closing Date"
                    value={loan.closingDate || ''}
                    onValueChange={(value) => handleOnChange("closingDate", value)}
                    errorText={validation.issuesObj.closingDate}
                  />
                </Form>
              </Grid>
              <Grid item md={6} xs={12} >
                <SectionHeaderRow text='Terms' />
                <Form disabled={isReadOnly} >
                  <CurrencyTextField          
                    label="Loan Amount"
                    value={loan.loanAmount || ''}
                    onValueChange={(value) => handleOnChange("loanAmount", value)}
                    errorText={validation.issuesObj.loanAmount}
                  />
                  <PercentTextField          
                    label="Interest Rate"
                    value={loan.interestRate || ''}
                    onValueChange={(value) => handleOnChange("interestRate", value)}
                    errorText={validation.issuesObj.interestRate}
                  />
                  <PercentTextField          
                    label="Default Interest Rate"
                    value={loan.defaultInterestRate || ''}
                    onValueChange={(value) => handleOnChange("defaultInterestRate", value)}
                    errorText={validation.issuesObj.defaultInterestRate}
                  />
                  <DateField          
                    label="Maturity Date"
                    value={loan.maturityDate || ''}
                    onValueChange={(value) => handleOnChange("maturityDate", value)}
                    errorText={validation.issuesObj.maturityDate}
                  />
                  <SelectField
                  	label='Day Count'
                    options={dayCountOptions}
                    value={loan.dayCount}
                    onValueChange={(value) => handleOnChange('dayCount', value)}
                    style={{ width: 150 }}
                  />
                </Form>
              </Grid>
              <Grid item md={6} xs={12} >
                <SectionHeaderRow text='Payments' />
                <Form disabled={isReadOnly} >
                  <SelectField
                    label='Payment Schedule'
                    options={paymentScheduleOptions}
                    value={loan.paymentSchedule || ''}
                    onValueChange={(value) => handleOnChange('paymentSchedule', value)}
                    style={{ width: 150 }}
                  />
                  { loan.paymentSchedule === 'monthly' &&
                    <SelectField
                      label='Payment Amount'
                      options={paymentAmountOptions}
                      value={loan.paymentAmount || ''}
                      onValueChange={(value) => handleOnChange('paymentAmount', value)}
                      style={{ width: 150 }}
                    />
                  }
                  { loan.paymentSchedule === 'monthly' && loan.paymentAmount === 'fixed' &&
                    <CurrencyTextField          
                      label="Fixed Amount"
                      value={loan.paymentAmountFixed || ''}
                      onValueChange={(value) => handleOnChange("paymentAmountFixed", value)}
                    />
                  }
                  { loan.paymentSchedule === 'monthly' && loan.paymentAmount === 'fixed' &&
                    <SelectField
                      label='Apply Payment To'
                      options={applyFixedPaymentToOptions}
                      value={loan.applyFixedPaymentTo || ''}
                      onValueChange={(value) => handleOnChange('applyFixedPaymentTo', value)}
                      style={{ width: 150 }}
                    />
                  }
                  { loan.paymentSchedule === 'monthly' &&
                    <DateField          
                      label="First Payment"
                      value={loan.firstPaymentDate || ''}
                      onValueChange={(value) => handleOnChange("firstPaymentDate", value)}
                      errorText={validation.issuesObj.firstPaymentDate}
                    />
              	  }
                </Form>
              </Grid>

              <Grid item md={6} xs={12} >
              { loanId !== 'new' && loan.transactions().length > 0 &&
                <Typography variant='body1' color='secondary'>Warning: Transactions have already been recorded for this loan.  Changing its terms may make them inaccurate.  Please review all existing transactions.</Typography> 

              }
              </Grid>
            </Grid>
          </div>
        </div>
      </SaveCancelDialog>
    );
  }
}
