// React
import React, { useState } from 'react';
// Material UI
import { Grid, Divider } from '@material-ui/core';
// Other Third-Party
// Local
import Frame from '../layout/Frame';
import { DetailSection, DetailSectionField } from '../layout/DetailSection';
import { DateField } from '../controls';
import { today, isDate } from '../Util';




export default function Payoff({loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [payoffDate, setPayoffDate] = useState(today());

  if( loan === undefined )
    return ( 
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/loans/' + loanId} >
        <div>Loading...</div>
      </Frame>
    );
  
  if( loan === 'not-found' )
    return ( 
      <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/loans/' + loanId} >
        <div>Not found</div>
      </Frame>
    );

  const payoff = loanBL.getPayoff(loan, payoffDate );
  console.log(payoff);

/*
            */
  return (
    <Frame title={loan?.nickname} backLabel={'Back'} backTo={'/loans/' + loanId} >
      <Grid container spacing={3} style={{ margin: 0 }} >
        
        <Grid item md={6} xs={12} >
          <DetailSection title='Payoff' style={{height: '100%'}} >
            <div style={{ width: '100%', height: 40, display: 'flex', flexDirection: 'row', fontSize: '16px', alignItems: 'center' }} >
              <div style={{ width: '30%' }} >Payoff Date</div>
              <div style={{ width: '70%', fontWeight: 'bold' }} >
                <DateField          
                  value={payoffDate || ''}
                  onValueChange={(value) => setPayoffDate(isDate(value) ? value : undefined)}
                />
              </div>
            </div>
            <Divider style={{width: '90%', margin: '16px'}} />
            <DetailSectionField label='Payoff Amount' value={payoff.total.format2()} />
            <DetailSectionField label='Daily Interest' value={payoff.dailyInterest.round2().format2()} />
          </DetailSection>
        </Grid>
        
        <Grid item md={6} xs={12} >
          <DetailSection title='Breakout' style={{height: '100%'}} >
            <DetailSectionField label='Principal' value={payoff.principal.format2()} />
            <DetailSectionField label='Interest' value={payoff.interest.format2()} />
            <DetailSectionField label='Charges' value={payoff.charges.format2()} />
            <DetailSectionField label='Suspense' value={payoff.suspense.format2()} />
          </DetailSection>
        </Grid>

      </Grid>
    </Frame>
  );

}






