// React
import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Button } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ThreePartRow from './ThreePartRow';


const styles = (theme) => ({
  container: {
    height: '100vh',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
  },

  // Header
  header: {
    height: '56px',
    padding: '0px',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  closeButton: {
    width: '48px',
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },

  main: {
    // Height
    overflowY: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    // Width
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
  },
  // Main
  mainInner: {
    // Width
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md - theme.spacing(2) * 2,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  
  footer: {
    height: '56px',
    padding: '0px',
    backgroundColor: theme.palette.grey[300],
  },
  footerButton: {
    width: '130px',
  },
});
const useStyles = makeStyles(styles);


export default function SaveCancelDialog({children, title, onCancel, onSave, disableSave, onDelete}) {
  const classes = useStyles();

  if( ! onCancel ) console.log('Warning: onCancel is not defined.');
  
  const handleCancel = () => {
    if( onCancel ) onCancel();
  }
  const handleSave = () => {
    if( onSave ) onSave();
  }
  const handleDelete = () => {
    if( onDelete ) onDelete();
  }
      
  return (
    <div className={classes.container}>
      <ThreePartRow
        center={<Typography variant="h6" className={classes.title}>{title}</Typography>}
        right={<IconButton onClick={(event) => handleCancel()} className={classes.closeButton} ><CloseIcon /></IconButton>}
        className={classes.header}
        />

      
      <main className={classes.main}>
        <div className={classes.mainInner}>
          {children}
        </div>
      </main>


      <ThreePartRow
        left={ onDelete && [   <Button key={1} onClick={(event) => handleDelete()} className={classes.footerButton} color='secondary' >Delete</Button>
          ]}
        right={[  <Button key={1} onClick={(event) => handleCancel()} className={classes.footerButton} >Cancel</Button>,
                  <Button key={2} onClick={(event) => handleSave()} disabled={disableSave} color='primary' variant='contained' className={classes.footerButton} >Save</Button>
                ]}
        className={classes.footer}
        />
    </div>
  );
}
