// React
import React, { useState, useEffect, useContext } from 'react';
// Reach Router
import { Router } from "@reach/router"
// Material UI
import { } from '@material-ui/core';
// Local
import AppContext from '../AppContext';
import { createLoanBL } from '../LoanBusinessLogic';
import Loan from './Loan';
import LoanEdit from './LoanEdit';
import Advance from './Advance';
import Charge from './Charge';
import Payment from './Payment';
import Refund from './Refund';
import Writeoff from './Writeoff';
import InterestAccrualDetail from './InterestAccrualDetail';
import PaymentsDetail from './PaymentsDetail';
import LoanDefaults from './LoanDefaults';
import LoanSharing from './LoanSharing';
import Payoff from './Payoff';
import NotFound from '../NotFound';


export default function LoanRouter({loanId}) {
  const {currentUser, db} = useContext(AppContext);
  const [loan, setLoan] = useState(undefined);

  useEffect(() => {
    if( loanId && loanId !== 'new' && loanId !== 'not-found' )
      return db.subscribeToLoan(loanId, (loan) => setLoan(loan));
  }, [db, loanId]);

  const saveLoan = (newLoan) => {
    return db.setLoan(loanId, newLoan || loan);
  }

  const deleteLoan = (loanId) => {
    return db.deleteLoan(loanId);
  }

  const loanBL = loan && loan !== 'not-found' && createLoanBL(loan);
  const currentUserIsOwner = loan && loan.ownerUID === currentUser.uid;

  return (
    <Router style={{height: '100%'}}>
      <Loan path='/' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} deleteLoan={deleteLoan} currentUserIsOwner={currentUserIsOwner} />
      <LoanEdit path='terms' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <Advance path='advances/:advanceId' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <Charge path='charges/:chargeId' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <Payment path='payments/:paymentId' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <Refund path='refunds/:refundId' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <Writeoff path='writeoffs/:writeoffId' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <InterestAccrualDetail path='InterestAccrualDetail' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <PaymentsDetail path='PaymentsDetail' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <LoanDefaults path='defaults' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <LoanSharing path='sharing' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <Payoff path='payoff' loanId={loanId} loan={loan} loanBL={loanBL} setLoan={setLoan} saveLoan={saveLoan} currentUserIsOwner={currentUserIsOwner} />
      <NotFound default />
    </Router>
  );
}
