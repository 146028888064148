// React
import React, { useContext } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, MenuItem, Divider } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon, Person as PersonIcon } from '@material-ui/icons';
// Reach Router
import { navigate } from "@reach/router"
// Local
import AppContext from '../AppContext';
import { PopupMenuButton } from '../controls';


const useStyles = makeStyles((theme) => (
  {
    container: {
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
      height: '100%',
    },

    // Header
    header: {
      // Height
      height: '56px',
      flexGrow: 0,
      flexShrink: 0,
      // Children
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      // Other
      backgroundColor: theme.palette.primary.main,
    },
    backButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      
      width: 100,
      paddingTop: 'initial',
      paddingBottom: 'initial',
      [theme.breakpoints.down('xs')]: {
        width: 30,
        fontSize: 0,
      },
    },
    backIcon: {
      marginRight: -12,
    },
    title: {
      color: theme.palette.primary.contrastText,
    },
    signOutButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,

      width: 100,
      paddingTop: 'initial',
      paddingBottom: 'initial',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    signOutIconButton: {
      color: theme.palette.primary.contrastText,
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'initial'
      },
    },

    // Main
    main: {
      // Height
      overflowY: 'auto',
      flexGrow: 1,
      flexShrink: 1,

      width: '100%',

      display: 'flex',
      flexDirection: 'row',
    },
    // Main
    mainInner: {
      // Width
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.md - theme.spacing(2) * 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
      },

      display: 'flex',
      flexDirection: 'column',
    },
}));


export default function Frame({window, children, title, backLabel, backTo}) {
  const {doSignOut, currentUser} = useContext(AppContext);

	const classes = useStyles();

	const handleSignOutClick = (event) => {
		event.preventDefault();
		doSignOut();
  };

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        { backLabel && backTo ?
          <Button color="inherit" onClick={(e) => navigate(backTo)} className={classes.backButton} 
                  startIcon={<ArrowBackIosIcon className={classes.backIcon} />}>
            {backLabel}
          </Button>
          :
          <div className={classes.backButton} />
        }
        <Typography variant="h2" className={classes.title}>{title}</Typography>
        <PopupMenuButton icon={<PersonIcon />} >
          <MenuItemStatic><b>{currentUser.displayName}</b></MenuItemStatic>
          <MenuItemStatic>{currentUser.email}</MenuItemStatic>
          <MenuItemDivider />
    			<MenuItem onClick={(e) => handleSignOutClick(e)} >Sign Out</MenuItem>
        </PopupMenuButton>
      </header>

      <main className={classes.main}>
        <div className={classes.mainInner}>
          {children}
        </div>
      </main>
    </div>
  );
}

const MenuItemStatic = React.forwardRef(({ children }, ref) => {
	return (
		<MenuItem disabled={true} style={{ opacity: 1 }} >{children}</MenuItem>
	);
});

const MenuItemDivider = React.forwardRef(({ children }, ref) => {
	return (
		<MenuItem disabled={true} style={{ opacity: 1 }} ><Divider style={{width: '100%' }} />&nbsp;</MenuItem>
	);
});