import { isDate, day, addMonths, addDays, maxDate, today } from '../Util';
import { dayCountOptions, paymentScheduleOptions, paymentAmountOptions, applyFixedPaymentToOptions } from '.';
import { createPayment, createRefund, createAdvance, createCharge, createWriteoff } from '.';


const createLoanTemplate = function() { return {
    paymentSchedule: 'monthly',
    paymentAmount: 'interest',
    dayCount: 'act_365',
    isArchived: false,
    defaults: [],
    advances: [],
    payments: [],
    charges: [],
    refunds: [],
    writeoffs: [],

    validate: function() {
      const issues = [];

      if( ! isDate(this.closingDate) ) issues.push({field: 'closingDate', message: 'Required'});
      if( ! isDate(this.maturityDate) ) issues.push({field: 'maturityDate', message: 'Required' });
      if( isDate(this.closingDate) && isDate(this.maturityDate) )
        if( this.closingDate >= this.maturityDate ) issues.push({field: 'maturityDate', message: 'Maturity date < closing date.' });

      if( isNaN(this.interestRate) ) issues.push({field: 'interestRate', message: 'Number required' });
      else if( this.interestRate < 0 || this.interestRate > 100 ) issues.push({field: 'interestRate', message: 'Out of range' });
      if( isNaN(this.defaultInterestRate) ) issues.push({field: 'defaultInterestRate', message: 'Number required' });
      else if( this.defaultInterestRate < 0 || this.defaultInterestRate > 100 )  issues.push({field: 'defaultInterestRate', message: 'Required' });

      if( isNaN(this.loanAmount) ) issues.push({field: 'loanAmount', message: 'Required' });
      else if( this.loanAmount < 1 || this.loanAmount > 1000000000 ) issues.push({field: 'loanAmount', message: 'Out of range' });

      // Payments (monthly or none)
      if( ! paymentScheduleOptions.map( o => o.value ).includes(this.paymentSchedule) ) issues.push({field: 'paymentScheduleOptions', message: 'Invalid value' });
      if( this.paymentSchedule === 'monthly' ) {
        if( ! isDate(this.firstPaymentDate) ) issues.push({field: 'firstPaymentDate', message: 'Required' });
        else {
          if( day(this.firstPaymentDate) !== 1 ) issues.push({field: 'firstPaymentDate', message: 'First of month only' });
          if( addMonths(this.closingDate, 1) >= this.firstPaymentDate )  issues.push({field: 'firstPaymentDate', message: 'Less than closing date + 1m' });
          if( this.firstPaymentDate > this.maturityDate )  issues.push({field: 'firstPaymentDate', message: 'First payment date > maturity date.' });
        }

        // Payment Amount (fixed or interest)
        if( ! paymentAmountOptions.map( o => o.value ).includes(this.paymentAmount) ) issues.push({field: 'paymentAmount', message: 'Invalid value' });
        if( this.paymentAmount === 'fixed' ) {
          if( isNaN(this.paymentAmountFixed) ) issues.push({field: 'paymentAmountFixed', message: 'Invalid value' });
          if( ! applyFixedPaymentToOptions.map( o => o.value ).includes(this.applyFixedPaymentTo) ) issues.push({field: 'applyFixedPaymentToOptions', message: 'Invalid value' });
        }  
        if( this.paymentAmount === 'interest' && this.paymentAmountFixed ) issues.push({field: 'paymentAmountFixed', message: 'Must be blank when interest-only' });

      }
      else if( this.paymentSchedule === 'none') {
        if( this.firstPaymentDate !== undefined ) issues.push({field: 'firstPaymentDate', message: 'Must be blank for no payments'});
      }
      else
        return false;

      // Interest
      if( ! dayCountOptions.map( o => o.value ).includes(this.dayCount) ) issues.push({field: 'dayCount', message: 'Invalid value'});

      // Defaults
      for( let i=0; i<this.defaults.length; i++ ) {
        const d = this.defaults[i];
        if( d.defaultDate === undefined ) issues.push({field: 'defaultDate', message: 'Default date cannot be blank.'});
        if( d.cureDate === undefined )
          if( i < this.defaults.length-1 ) issues.push({field: 'cureDate', message: 'Only the last cure date can be blank.'});
        else {
          if( d.defaultDate > d.cureDate ) issues.push({field: 'cureDate', message: 'Cure date must be after default date.'});
          if( i > 0 && d.defaultDate <= this.defaults[i-1].cureDate ) issues.push({field: 'defaultDate', message: 'Default date must be after previous cure date.'});
        }
      }

      const issuesObj = {};
      issues.forEach( i => {
        if( !issuesObj[i.field] )
          issuesObj[i.field] = i.message;
      });

      return { isValid: issues.length === 0, issues: issues, issuesObj: issuesObj };
    },

    toPOJO: function() { return JSON.parse(JSON.stringify(this)) },

    clone: function() { return createLoan(this.toPOJO()) },

    getDefaultStatus: function() {
      if( this.status === 'Paid-Off' )
        return 'Paid-Off';
      else if( this.defaults.find( d => d.cureDate === undefined ) === undefined )
        return 'Clear'
      else
        return 'In Default';
    },

    getScheduledPaymentPeriods: function() {
      if( this.paymentSchedule === 'none' )
        return [{paymentDate: this.maturityDate, from: this.closingDate, to: this.maturityDate }];
      else {
        let paymentDates = []; //[{paymentDate: this.closingDate, from: this.closingDate, to: addDays(addMonths(this.firstPaymentDate, -1), -1)}];
        let dt = this.firstPaymentDate;
        const mxDate = maxDate(this.maturityDate, today());
        for( ; dt <= mxDate; dt = addMonths(dt,1) )
          paymentDates.push({paymentDate: dt, from: addMonths(dt, -1), to: addDays(dt, -1)});
        return paymentDates;
      }
    },
    getScheduledPaymentDates: function() {
      const schPaymentDates = [];
      const mxDate = maxDate(this.maturityDate, today());
      for( let dt = this.firstPaymentDate; dt <= mxDate; dt = addMonths(dt,1) )
        schPaymentDates.push(dt);
      return schPaymentDates
    },


    allCharges: function() {
      return this.charges.map( c => ({ asOfDate: c.asOfDate, principal: 0, interest: 0, charges: c.amount, suspense: 0, due: c.due }) )
            .concat( this.advances.flatMap( a => a.charges.map( c => ({ asOfDate: a.asOfDate, principal: 0, interest: 0, charges: c.charge, suspense: 0, due: c.due }) ) ) );
    },

    allPayments: function() {
      return this.payments
        .flatMap( (p,paymentId) => p.allocations.map( a => ({ paymentId: paymentId, receivedDate: p.receivedDate, type: a.type, asOfDate: a.asOfDate, principal: -a.principal, interest: -a.interest, charges: -a.charges, suspense: 0 }) ) )
        .concat( this.payments.filter( p => p.suspense !== 0 ).map( (p,paymentId) => ({ paymentId: paymentId, receivedDate: p.receivedDate, type: 'unscheduled', asOfDate: p.receivedDate, principal: 0, interest: 0, charges: 0, suspense: p.suspense }) ) );
    },

    transactions: function() {
      return this.allPayments().map( p => Object.assign( p, { type: 'payment', paymentType: p.type } ) )
            .concat( this.advances.map( a => ({ type: 'advance', asOfDate: a.asOfDate, principal: a.principal, 
                                              interest: (a.prepaidInterest !== 0 && a.prepaidInterestDue === 'net' ? -a.prepaidInterest : 0),
                                              charges: 0, suspense: 0 }) ) )
            .concat( this.refunds.map( r => ({ type: 'refund', asOfDate: r.asOfDate, principal: r.principal, interest: r.interest, charges: r.charges, suspense: r.suspense }) ) )
            .concat( this.writeoffs.map( w => ({ type: 'writeoff', asOfDate: w.asOfDate, principal: -w.principal, interest: -w.interest, charges: -w.charges, suspense: w.suspense }) ) )
            .concat( this.allCharges().map( c => ({ type: 'charge', asOfDate: c.asOfDate, principal: 0, interest: 0, charges: c.charges, suspense: 0, due: c.due }) ) );
    },

  };
}

export const createLoan = (data) => {
  const l = Object.assign( createLoanTemplate(), data );

  if( l.advances ) l.advances = l.advances.map( (i,index) => createAdvance(index, i) );
  if( l.charges ) l.charges = l.charges.map( (i,index) => createCharge(index, i) );
  if( l.payments ) l.payments = l.payments.map( (i,index) => createPayment(index, i) );
  if( l.refunds ) l.refunds = l.refunds.map( (i,index) => createRefund(index, i) );
  if( l.writeoffs ) l.writeoffs = l.writeoffs.map( (i,index) => createWriteoff(index, i) );

  return l;
}
