const config = {
  apiKey: "AIzaSyDSz6Qal1vSuiSPWunVNPiILpm9I05sG7c",
  authDomain: "entryway-servicing-dev.firebaseapp.com",
  projectId: "entryway-servicing-dev",
  storageBucket: "entryway-servicing-dev.appspot.com",
  messagingSenderId: "70791051743",
  appId: "1:70791051743:web:33b6175dbd7b4188de6c6a",
  measurementId: "G-TBZN2J27CX"
};


export default config;