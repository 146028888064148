import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as ReactIs from 'react-is';


const styles = (theme) => ({
  container: {
    display: 'flex', 
    flexDirection: 'column'
  }
});
const useStyles = makeStyles(styles);



export const Form = (props) => {
  const {
    children,
    disabled,
    className,
    ...otherProps
  } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)} {...otherProps} >
      { Array.isArray(children) ?
          children.map( (child, index) => {
            if( ReactIs.isFragment(child) )
              return child;
            else if( React.isValidElement(child) ) 
              return React.cloneElement(child, {key: 'k'+index, disabled: disabled}, null);
            else
              return child;
          })
        :
        children
      }  
    </div>
  );

}

export default Form;
