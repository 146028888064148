// React
import React, { useState, useEffect } from 'react';
// Material UI
import { Grid, TableContainer, Table, Paper, TableHead, TableBody, TableRow, TableCell, Divider } from '@material-ui/core';
// Other Third-Party
// Local
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, CurrencyTextField, DateField, AddButton, DeleteButton, SelectField } from '../controls';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import Form from '../Form';
import { setDeep } from '../Util';
import { DetailSection, DetailSectionField } from '../layout/DetailSection';
import { createAdvance } from '../model';
import { createLoanBL } from '../LoanBusinessLogic';




export default function Advance({advanceId, loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const [advance, setAdvance] = useState(undefined); 

  useEffect(() => {
    if( loan === undefined )
      setAdvance(undefined);
    else if( loan === 'not-found' )
      setAdvance('not-found');
    else if( advanceId === 'new' )
      setAdvance(createAdvance());
    else if( ! loan.advances[advanceId] )
      setAdvance('not-found');
    else
      setAdvance(loan.advances[advanceId]);
  }, [loan, advanceId]);

  const handleCancel = () => {
    navigate('/loans/' + loanId);
  }
  const handleSave = () => {
    const newLoan = loan.clone();
    if( advanceId === 'new' )
      newLoan.advances.push(advance);
    else
      newLoan.advances[advanceId] = advance;
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }
  const handleDelete = () => {
    const newLoan = loan.clone();
    newLoan.advances.splice(advanceId, 1);
    saveLoan(newLoan);
    navigate('/loans/' + loanId);
  }

  const handleOnChange = (name, value) => {
    let newAdvance = advance.clone();
    setDeep( newAdvance, name, value );

    if( loan.paymentSchedule === 'monthly' && (name === 'principal' || name === 'asOfDate') ) {
      const loanBL = createLoanBL(loan);
      newAdvance.prepaidInterest = loanBL.calcPrepaidInterest(loan, newAdvance.asOfDate, newAdvance.principal);
    }

    setAdvance( newAdvance );
  };

  const handleOnAddCharge = () => {
    setAdvance( (prevState, props) => {
      let newAdvance = prevState.clone();
      newAdvance.charges.push({ description: undefined, charge: 0 });
      return newAdvance;
    });
  }

  const handleOnDeleteCharge = (index) => {
    setAdvance( (prevState, props) => {
      let newAdvance = prevState.clone();
      newAdvance.charges = newAdvance.charges.length === 1 ? [] : newAdvance.charges.splice(index,1);
      return newAdvance
    });
  }

  const handleOnChangeCharge = (index, name, value) => {
    setAdvance( (prevState, props) => {
      let newAdvance = prevState.clone();
      console.log('newAdvance', newAdvance);
      newAdvance.charges[index][name] = value;
      return newAdvance
    });
  }

  const isReadOnly = (!currentUserIsOwner) || loan.isArchived;

  if( advance === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  if( advance === 'not-found' )
    return ( 
      <SaveCancelDialog title='Not Found' onCancel={() => handleCancel()} >
        <div>Not found</div>
      </SaveCancelDialog>
    );

  const dueOptions = [{ value: 'net', label: 'Net From Advance' }, { value: 'immediately', label: 'Immediately' }, {value: 'termination', label: 'At Termination'}];

  return (
    <SaveCancelDialog title='Advance' 
                      onCancel={() => handleCancel()} 
                      onSave={() => handleSave()} 
                      onDelete={ advanceId !== 'new' && (!isReadOnly) && ( () => handleDelete() )}
                      disableSave={(!advance.isValid()) || isReadOnly} >
      <Grid container spacing={3} style={{ margin: 0 }} >

        <Grid item md={6} xs={12} >
          <DetailSection title='Advance' style={{height: '100%'}} >
            <Form style={{width: '100%'}} disabled={isReadOnly} >
              <DateField          
                label="As Of"
                value={advance.asOfDate || ''}
                onValueChange={(value) => handleOnChange("asOfDate", value)}
              />
              <CurrencyTextField
                label="Principal"
                value={advance.principal || ''}
                onValueChange={(value) => handleOnChange("principal", value)}
              />
              <MyTextField
                label="Notes"
                value={advance.notes || ''}
                onValueChange={(value) => handleOnChange("notes", value)}
              />
              <Divider style={{ width: '80%', marginTop: '8px', marginBottom: '8px' }} />
              { loan.paymentSchedule === 'monthly' &&
                <CurrencyTextField
                  label='Prepaid Interest'
                  value={advance.prepaidInterest}
                  onValueChange={(value) => handleOnChange('prepaidInterest', value)}
                />
              }
              { loan.paymentSchedule === 'monthly' &&
                <SelectField
                  label='Prepaid Interest Due'
                  options={dueOptions}
                  value={advance.prepaidInterestDue || ''}
                  onValueChange={(value) => handleOnChange('prepaidInterestDue', value)}
                />
              }
            </Form>
          </DetailSection>
        </Grid>

        <Grid item md={6} xs={12} >
          <DetailSection title='Cash' style={{height: '100%'}} >
            <DetailSectionField label='Advance' value={(advance.principal || 0).format2()} />
            <DetailSectionField label='Charges' value={(-advance.netCharges()).format2()} />
            <DetailSectionField label='Prepaid Interest' value={(-advance.netPrepaidInterest()).format2()} />
            <DetailSectionField label='Net Advance' value={advance.cash().format2()} />
            <Divider style={{ width: '80%', marginTop: '8px', marginBottom: '8px' }} />
            <DetailSectionField label='Due Back' value={advance.paymentDue().total.format2()} />
          </DetailSection>
        </Grid>

        <Grid item md={12} xs={12} >
          <SectionHeaderRow text='Charges'
                            right={
                                <div>
                                  <AddButton onAdd={() => handleOnAddCharge()} style={{marginRight: 20}} disabled={isReadOnly} />
                                </div>
                              } />
          <TableContainer component={Paper} >
            <Table style={{minWidth: 600}} >
              <TableHead>
                <TableRow >
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Amount</TableCell>
                  <TableCell align="left">Due</TableCell>
                  <TableCell align="left">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { advance.charges.length === 0 ?
                  <TableRow >
                    <TableCell align="center" colSpan='3' >No charges</TableCell>
                  </TableRow>
                  : advance.charges.map((c, index) => {
                  return (
                    <TableRow key={index} >
                      <TableCell align="left">
                        <MyTextField
                          value={c.description || ''}
                          onValueChange={(value) => handleOnChangeCharge(index, "description", value)}
                          disabled={isReadOnly}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <CurrencyTextField
                          value={c.charge || ''}
                          onValueChange={(value) => handleOnChangeCharge(index, 'charge', value)}
                          disabled={isReadOnly}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <SelectField
                          options={dueOptions}
                          value={c.due || ''}
                          onValueChange={(value) => handleOnChangeCharge(index, 'due', value)}
                          style={{width:180}}
                          disabled={isReadOnly}
                        />
                      </TableCell>
                      <TableCell>
                        <DeleteButton onDelete={() => handleOnDeleteCharge(index)}
                          disabled={isReadOnly} />
                      </TableCell>
                    </TableRow> );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </SaveCancelDialog>
  );

}






