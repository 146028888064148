// React
import React, { useState, useEffect } from 'react';
// Material UI
//import firebase from 'firebase/app';
// Other Third-Party
import { Router } from "@reach/router"

// Local
import AppContext from './AppContext';
import SignInScreen from './SignInScreen';
import Home from './Home';
import NotFound from './NotFound'; 
import LoanRouter from './loan/LoanRouter';
import { createDb } from './DataStore'; 
import './App.css';

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [db, setDb] = useState(undefined);

  useEffect(() => {
    createDb().then( _db => setDb(_db));
  }, []);

  useEffect(() => {
    if( db !== undefined )
      db.firebase.auth().onAuthStateChanged(user => {
        db.init(user);
        setCurrentUser( user );
      });
  }, [db]);


  if( db === undefined )
    return <div>Loading...</div>;

  if( currentUser === undefined || currentUser === null )
    return ( <SignInScreen firebase={db.firebase} /> );

  return ( 
    <AppContext.Provider value={{
            firebase: db.firebase,
            currentUser: currentUser,
            db: db,
            doSignOut: () => { db.firebase.auth().signOut(); }
          }}>
      <Router style={{height: '100%'}}>
        <Home path='/' />
        <LoanRouter path='/loans/:loanId/*' />
        <NotFound default />
      </Router>
    </AppContext.Provider>
  );

}

export default App;
