// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
// Other Third-Party
// Local
import firebaseConfigLocal from './firebase.config';
import { createLoan } from './model';



const getFirebaseConfig = () => {
  return fetch('/__/firebase/init.json')
    .then(response => {
      if( !response.ok )
        return firebaseConfigLocal;
      return response.json();
    })
    .catch(error => {
      return firebaseConfigLocal;
    });
}

const DataStore = {

  getLoans: function() {
    const getSharedLoans = firebase.app().functions('us-east4').httpsCallable('getSharedLoans');
    return getSharedLoans().then( result => {
      if( ! result.data.success ) {
        console.error( 'getLoans', result.data.message );
        return undefined;
      }

      const loanIds = result.data.data;
      const ps = loanIds.map( loanId => this.getLoan( loanId, undefined ) );
      return Promise.all(ps)
      .then( (values) => {
        return values.filter( v => v !== undefined )
      })
      .catch( e => {
        console.error('getLoan', e);
      });
    });
  },

  getOwnedLoans: function(includeArchived = false) {
    if( this.user === undefined )
      return undefined;

    let query = firebase.firestore().collection('loans').where('ownerUID', '==', this.user.uid);
    if( ! includeArchived )
      query = query.where('isArchived', '==', false);

    return query.get().then( querySnapshot => {
      if( querySnapshot.empty )
        return [];

      const ps = querySnapshot.docs.map( loanDoc => this.getLoan( loanDoc.id, loanDoc) );
      return Promise.all(ps)
      .then( (values) => {
        return values.filter( v => v !== undefined )
      })
      .catch( e => {
        console.error('getLoan', e);
      });
    })
    .catch(e => {
      console.error('loans', e);
    });
  },

  getLoan: function(loanId, loanDoc) {
      return firebase.firestore().collection('loans').doc(loanId).get()
      .then( doc => {
        if( ! doc.exists )
          return undefined;

        const loan = createLoan(doc.data());
        loan.id = doc.id;

        return loan;
      });
  },

  subscribeToLoan: function(loanId, onChange) {
    return firebase.firestore().collection('loans').doc(loanId).onSnapshot( doc => onChange( doc.exists ? createLoan(doc.data()) : 'not-found' ) );
  },

  setLoan: function(loanId, loan) {
  	// Get the save-able data
  	const data = loan.toPOJO();

    const ref = firebase.firestore().collection('loans').doc(loanId === 'new' ? undefined : loanId);
    return ref.set(data).then(() => {
      return ref.id;
    })
    .catch((error) => {
      console.log('Error setting loan: ', error);
    });
  },

  deleteLoan: function(loanId) {
    return firebase.firestore().collection('loans').doc(loanId).delete();
  },

  getLoanSharing: function(loanId) {
    return firebase.firestore().collection('loan_sharing').doc(loanId).get().then( doc => {
      return doc.exists ? doc.data() : { emails: [] };
    })
    .catch(e => {
      console.error('getLoanSharing', e);
    })
  },

  setLoanSharing: function(loanId, loanSharing) {
    return firebase.firestore().collection('loan_sharing').doc(loanId).set(loanSharing)
    .catch(e => {
      console.error('setLoanSharing', e);
    })
  },

  sendInvitation: function( toEmail, senderUID, senderEmail, senderName, loanBorrower, loanProperty, loanAmount ) {
    const email = {
      from: 'Entryway Loan Servicing <donotreply@entrywayloans.com>',
      replyTo: senderName + '<' + senderEmail + '>',
      to: toEmail,
      template: {
        name: 'invitation',
        data: {
          sender: senderName,
          loanBorrower: loanBorrower,
          loanProperty: loanProperty,
          loanAmount: loanAmount
        }
      },
      senderUID: senderUID
    };

    return firebase.firestore().collection('emails').add(email).then((doc) => doc.id);
  },

  init: function(user) {
    this.user = user;
  }

};

export const createDb = () => {
  return getFirebaseConfig().then( config => {
    firebase.initializeApp(config);
    DataStore.firebase = firebase;
    return DataStore;
  });
}


