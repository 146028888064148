// React
import React, { useContext, useEffect, useState } from 'react';
// Material UI
// Other Thrid-Party
import { navigate } from "@reach/router"
// Local
import AppContext from './AppContext';
import Frame from './layout/Frame';
import CardListSection from './layout/CardListSection';
import { AddButton, SwitchField } from './controls';
import { createLoanBL } from './LoanBusinessLogic';


//const styles = (theme) => ({});
//const useStyles = makeStyles(styles);


export default function Home() {
  const statusColors = { 'Paid-Off': 'blue', 'Late': 'red', 'Current': 'green' };
  const {db} = useContext(AppContext);
  const [ownedLoans, setOwnedLoans] = useState(undefined); 
  const [viewedLoans, setViewedLoans] = useState(undefined);
  const [showArchived, setShowArchived] = useState(false); 

  useEffect(() => {
    db.getOwnedLoans(showArchived).then( newLoans => {
      setOwnedLoans(newLoans);
    });
  }, [db, showArchived]);

  useEffect(() => {
    db.getLoans().then( newLoans => {
      setViewedLoans(newLoans);
    });
  }, [db]);


  return (
    <Frame title='Entryway Servicing' backLabel={undefined} backTo={undefined} >

      <CardListSection
          title='My Loans'
          headerRight={ 
            <React.Fragment>
              <AddButton label='New' onAdd={() => navigate('/loans/new/terms')} style={{marginRight: 20}} />
              <SwitchField label='Archived' value={showArchived} onValueChange={(checked) => setShowArchived(checked) } />
            </React.Fragment>
          }
          getCardTitle={(loan) => loan?.nickname || "New Loan" }
          getCardUrl={(loan) => '/loans/' + loan.id }
          getCardRight={(loan) => {
            const loanBL = createLoanBL(loan);
            return <div style={{color: statusColors[loanBL.status]}} >{loanBL.status}</div>
          }}
          values={ownedLoans}
        />  

      <CardListSection
          title='Shared With Me'
          getCardTitle={(loan) => loan?.nickname || "New Loan" }
          getCardUrl={(loan) => '/loans/' + loan.id }
          getCardRight={(loan) => {
            const loanBL = createLoanBL(loan);
            return <div style={{color: statusColors[loanBL.status]}} >{loanBL.status}</div>
          }}
          values={viewedLoans}
        />  

    </Frame>
  );
}

