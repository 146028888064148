import { isDate, round2Decimals } from '../Util';


const paymentTemplate = {
  cash: 0,
  suspense: 0,
  allocations: [],

  isValid: function() {
    if( ! isDate(this.receivedDate) ) return false;
    if( this.cash < 0 ) return false;
    //if( this.suspense < 0 ) return false;
    for( let i=0; i<this.allocations.length; i++ )
    	if( ! this.allocations[i].isValid() ) return false;
    if( this.totalUnapplied() !== 0 ) return false;
    return true;
  },

  newAllocation: function() {
    const newAllocation = Object.assign({}, allocationTemplate);
    this.allocations.push(newAllocation);
    return newAllocation;
  },

  totalApplied: function() {
  	const sumAllocs = this.allocations.sum(['principal','interest','charges']);
  	return round2Decimals( this.suspense + sumAllocs.principal + sumAllocs.interest + sumAllocs.charges );
  },

  totalUnapplied: function() {
  	return round2Decimals( this.cash - this.totalApplied() );
  },

  toPOJO: function() { return JSON.parse(JSON.stringify(this)) },

  clone: function() { return createPayment(this.paymentId, this.toPOJO()) }
};


const allocationTemplate = {
  type: undefined,
	asOfDate: undefined, 
	interest: 0, 
	principal: 0, 
	charges: 0,

	isValid: function() {
      if( ! allocationTypeOptions.map( o => o.value ).includes(this.type) ) return false;
    	if( ! isDate(this.asOfDate) ) return false;
    	if( this.interest < 0 ) return false;
    	if( this.principal < 0 ) return false;
    	if( this.charges < 0 ) return false;
    	return true;
	}
};


export const allocationTypeOptions = [
    { value: 'scheduled', label: 'Scheduled' },
    {value:'unscheduled', label: 'Unscheduled'} ];


export const createPayment = (id, data) => {
  const pmt = Object.assign( {}, paymentTemplate, data, { paymentId: id } );
  pmt.allocations = pmt.allocations.map( a => Object.assign({}, allocationTemplate, a) );
  return pmt;
}