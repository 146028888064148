// React
import React, { useState, useEffect, useContext } from 'react';
// Material UI
import { Grid, TableContainer, Table, Paper, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
// Other Third-Party
// Local
import AppContext from '../AppContext';
import SaveCancelDialog from '../layout/SaveCancelDialog';
import { MyTextField, AddButton } from '../controls';
import SectionHeaderRow from '../layout/SectionHeaderRow';
import { DeleteButton } from '../controls';
import { isValidEmailAddress } from '../Util';


export default function LoanSharing({loanId, loan, loanBL, setLoan, saveLoan, currentUserIsOwner, navigate}) {
  const {db, currentUser} = useContext(AppContext);
  const [loanSharing, setLoanSharing] = useState(undefined);

  useEffect(() => {
    db.getLoanSharing(loanId).then( (ls) => {
      setLoanSharing(ls);
    });
  }, [db, loanId]);


  const handleCancel = () => {
    navigate('/loans/' + loanId);
  }
  const handleSave = () => {
    const loanSharingToSave = JSON.parse(JSON.stringify(loanSharing));
    loanSharingToSave.emails = loanSharingToSave.emails.map( email => email.toLowerCase());
    db.setLoanSharing(loanId, loanSharingToSave);
    navigate('/loans/' + loanId);
  }

  const handleOnChange = (index, value) => {
    const newLoanSharing = JSON.parse(JSON.stringify(loanSharing));
    newLoanSharing.emails[index] = value;
    setLoanSharing( newLoanSharing );
  };

  const handleOnAdd = () => {
    const newLoanSharing = JSON.parse(JSON.stringify(loanSharing));
    newLoanSharing.emails.push('');
    setLoanSharing( newLoanSharing );
  }

  const handleOnDelete = (index) => {
    const newLoanSharing = JSON.parse(JSON.stringify(loanSharing));
    newLoanSharing.emails.splice(index,1);
    setLoanSharing( newLoanSharing );
  }

  const handleOnInvite = (index) => {
    console.log('handleOnInvite', 'loanSharing.emails[index]', loanSharing.emails[index]);
    db.sendInvitation( loanSharing.emails[index], currentUser.uid, currentUser.email, currentUser.displayName, loan.borrowers, loan.property, loan.loanAmount.format2() );
  }


  if( loan === undefined || loanSharing === undefined )
    return ( 
      <SaveCancelDialog title='Loading' onCancel={() => handleCancel()} >
        <div>Loading...</div>
      </SaveCancelDialog>
    );
  
  const emailErrorTexts = loanSharing.emails.map( email => isValidEmailAddress(email) ? undefined : 'Invalid email' );
  const disableSave = emailErrorTexts.reduce( (s,i) => s || i ? true : false, undefined );
  
  return (
    <SaveCancelDialog title='Loan Sharing' onCancel={() => handleCancel()} onSave={() => handleSave()} disableSave={disableSave} >
      <Grid container spacing={3} style={{ margin: 0 }} >


        <Grid item xs={12} >
          <SectionHeaderRow text='Shared With'
                            right={ <div><AddButton onAdd={() => handleOnAdd()} /></div> } />
          <TableContainer component={Paper} >
            <Table style={{minWidth: 650}} >
              <TableHead>
                <TableRow >
                  <TableCell align="left" >Email</TableCell>
                  <TableCell align="left">&nbsp;</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { loanSharing.emails.length === 0 ?
                  <TableRow >
                    <TableCell align="center" colSpan='6' >None found</TableCell>
                  </TableRow>
                  : loanSharing.emails
                    .map( (email,index) =>
                        <TableRow key={index} >
                          <TableCell align="left" >
                            <div style={{ display: 'flex', flexDirection: 'column' }} >
                              <MyTextField          
                                value={email || ''}
                                onValueChange={(value) => handleOnChange(index, value)}
                                errorText={ emailErrorTexts[index] }
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <Button onClick={() => handleOnInvite(index)} disabled={emailErrorTexts[index] !== undefined} >Invite</Button>
                          </TableCell>
                          <TableCell align="right">
                            <DeleteButton onDelete={() => handleOnDelete(index)} />
                          </TableCell>
                        </TableRow>
                      )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </SaveCancelDialog>
  );

}






